import { render, staticRenderFns } from "./toggleHeader.vue?vue&type=template&id=645cf19f&scoped=true&"
import script from "./toggleHeader.vue?vue&type=script&lang=js&"
export * from "./toggleHeader.vue?vue&type=script&lang=js&"
import style0 from "./toggleHeader.vue?vue&type=style&index=0&id=645cf19f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645cf19f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/Lcp.VisualisePro/Lcp.VisualisePro.WebVue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('645cf19f')) {
      api.createRecord('645cf19f', component.options)
    } else {
      api.reload('645cf19f', component.options)
    }
    module.hot.accept("./toggleHeader.vue?vue&type=template&id=645cf19f&scoped=true&", function () {
      api.rerender('645cf19f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/uiInputs/toggleHeader.vue"
export default component.exports