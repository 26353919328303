var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.consolidated
    ? _c("consolidatedDeriskingPage")
    : _c("deriskingPage")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }