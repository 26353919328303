var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "table",
      { staticClass: "tileMetricTableBoldLastValue" },
      [
        _vm.headers
          ? _c(
              "tr",
              { staticClass: "borderBottom" },
              _vm._l(_vm.headers, function (header) {
                return _c("td", { key: header }, [_vm._v(_vm._s(header))])
              }),
              0
            )
          : _vm._e(),
        _vm._l(_vm.tileData.metrics, function (metric) {
          return _c(
            "tr",
            {
              key: metric.description,
              staticClass: "mainBodyRow",
              class:
                _vm.chartData && _vm.chartData.chartOptions[metric.description]
                  ? "containsChart"
                  : "",
            },
            [
              _vm.chartData && _vm.chartData.chartOptions[metric.description]
                ? _c(
                    "td",
                    { staticClass: "tableChart" },
                    [
                      _c("highchart", {
                        key: "fundingChart_" + metric.description,
                        attrs: {
                          options:
                            _vm.chartData.chartOptions[metric.description],
                          "series-options":
                            _vm.chartData.chartSeries[metric.description],
                          hideHiddenChartText: true,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("td", [_vm._v(_vm._s(metric.description))]),
              _c("td", [_vm._v(_vm._s(_vm.getMetricDisplayValue(metric)))]),
            ]
          )
        }),
        _vm._l(_vm.dummyMetricRows, function (i) {
          return _c("tr", { key: i }, [
            _c("td", [_vm._v(" ")]),
            _c("td", [_vm._v(" ")]),
          ])
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.coverText,
            expression: "coverText",
          },
        ],
        staticClass: "coverText",
        style: { background: _vm.tileData.colour },
      },
      [_c("div", { staticClass: "tileRow" }, [_vm._v(_vm._s(_vm.coverText))])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }