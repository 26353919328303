var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageAvailable
    ? _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-md-8 framed partialPageCardLeft",
            attrs: { id: "mainCard" },
          },
          [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-header" },
                [
                  _c("dropdown-title", {
                    attrs: {
                      dropdownPrefix: _vm.header + " - ",
                      inputTemplate:
                        _vm.inputs[_vm.config.accountingPeriodControlId],
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "LCPcontent" }, [
                _c("div", { ref: "chart" }),
                _c(
                  "div",
                  { staticClass: "controls" },
                  [
                    _c("input-wrapper", {
                      attrs: {
                        inputTemplate: _vm.getAccountingMeasureControlInput(),
                      },
                    }),
                    _c("lcp-table", {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { config: _vm.tableData, responsiveSize: "md" },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _c("div", { staticClass: "col-md-4 partialPageCardRight" }, [
          _vm.config.showCreateReport
            ? _c(
                "div",
                { staticClass: "card separateCard" },
                [
                  _vm.config.showCreateReport
                    ? _c("download-report", {
                        attrs: {
                          btnText: "Download report",
                          reportType: _vm.config.accountingReportId,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "card" },
            [
              _c(
                "div",
                { staticClass: "card-header-with-action" },
                [
                  _c("h1", [
                    _vm._v(
                      "Assumptions as at " +
                        _vm._s(_vm.config.calculationDate.ToDateString())
                    ),
                  ]),
                  _c("reset-inputs"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-12 input-container" },
                _vm._l(
                  _vm.getAssumptionInputs(
                    _vm.inputs,
                    _vm.dynamicOutput.controlsToDisplay
                  ),
                  function (input, key) {
                    return _c("input-wrapper", {
                      key: key,
                      attrs: { inputTemplate: input },
                    })
                  }
                ),
                1
              ),
              _c(
                "vue-markdown",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.period == _vm.config.thisYearPeriodId,
                      expression: "period == config.thisYearPeriodId",
                    },
                  ],
                },
                [
                  _vm._v(
                    "The numbers shown are projected from " +
                      _vm._s(_vm.config.calculationDate.ToDateString()) +
                      " in line with the assumptions and take no account of experience from that date. The actual accounting numbers for the period will be different if experience differs from the assumptions made."
                  ),
                ]
              ),
              _c(
                "vue-markdown",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.period == _vm.config.nextYearPeriodId,
                      expression: "period == config.nextYearPeriodId",
                    },
                  ],
                },
                [
                  _vm._v(
                    "The numbers shown are illustrative only. They are projected from " +
                      _vm._s(_vm.config.calculationDate.ToDateString()) +
                      " in line with the assumptions and take no account of experience from that date. The actual accounting numbers for the period will be different if experience differs from the assumptions made."
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ])
    : _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } },
          [
            _c(
              "div",
              { staticClass: "card" },
              [
                _c("div", { staticClass: "card-header" }, [
                  _c("h1", [_vm._v(_vm._s(_vm.header))]),
                ]),
                _c("vue-markdown", {
                  staticClass: "LCPcontent",
                  attrs: { source: _vm.pageNotAvailableText },
                }),
              ],
              1
            ),
          ]
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }