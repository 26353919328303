var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("highchart", {
    key: "riskReturn",
    attrs: {
      options: _vm.chartOptionsRiskReturn,
      "series-options": _vm.chartSeriesRiskReturn,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }