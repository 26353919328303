var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12", attrs: { id: "mainCard" } },
      [
        _c(
          "div",
          {
            directives: [{ name: "masonry", rawName: "v-masonry" }],
            attrs: {
              "origin-left": "true",
              "transition-duration": "1s",
              "item-selector": ".item",
            },
          },
          [
            _vm._l(_vm.tiles, function (tile) {
              return _c(
                "div",
                {
                  directives: [
                    { name: "masonry-tile", rawName: "v-masonry-tile" },
                  ],
                  key: tile.id,
                  staticClass: "item col-md-12",
                },
                [
                  _c("tile-wrapper", {
                    attrs: {
                      id: tile.id,
                      tileData: tile,
                      preLinkAction: _vm.preLinkAction(tile.id),
                    },
                  }),
                ],
                1
              )
            }),
            _vm._l(_vm.notes, function (note) {
              return _c(
                "div",
                {
                  directives: [
                    { name: "masonry-tile", rawName: "v-masonry-tile" },
                  ],
                  key: note,
                  staticClass: "item col-md-12",
                },
                [
                  _c("note", {
                    attrs: { id: note, tileData: null },
                    on: { newNote: _vm.addNote, removeNote: _vm.removeNote },
                  }),
                ],
                1
              )
            }),
          ],
          2
        ),
        _vm.$store.state.dashboard.editDashboardOpen
          ? _c("edit-dashboard", {
              attrs: { dashboardTiles: _vm.dashboardTiles },
              on: {
                close: function ($event) {
                  _vm.$store.state.dashboard.editDashboardOpen = false
                },
                redrawTiles: _vm.updateTiles,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }