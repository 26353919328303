var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageAvailable
    ? _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-md-8 framed partialPageCardLeft",
            attrs: { id: "mainCard" },
          },
          [
            _c(
              "div",
              { staticClass: "card" },
              [
                _c("toggle-header", {
                  attrs: {
                    tabs: _vm.tabs,
                    viewOverride: _vm.currentView,
                    title: _vm.config.recoveryPlanName,
                    showTabsOverride: true,
                  },
                  on: { currentView: _vm.onCurrentViewUpdate },
                }),
                _vm.currentView === _vm.progressView
                  ? _c(
                      "div",
                      [
                        _c("lcp-table", {
                          staticClass: "LCPcontent",
                          attrs: {
                            config: _vm.progressTableData,
                            id: _vm.progressView,
                          },
                        }),
                        _c("highchart", {
                          key: "progress",
                          staticClass: "LCPcontent",
                          attrs: {
                            options: _vm.chartOptionsProgress,
                            "series-options": _vm.chartSeriesProgress,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.currentView === _vm.assetLiabilityView
                  ? _c(
                      "div",
                      [
                        _c("lcp-table", {
                          staticClass: "LCPcontent",
                          attrs: {
                            config: _vm.assetLiabilityTableData,
                            id: _vm.assetLiabilityView,
                          },
                        }),
                        _c("highchart", {
                          key: "assetLiability",
                          staticClass: "LCPcontent",
                          attrs: {
                            options: _vm.chartOptionsAL,
                            "series-options": _vm.chartSeriesAssetAndLiability,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.currentView === _vm.paymentView
                  ? _c(
                      "div",
                      [
                        _c("lcp-table", {
                          staticClass: "LCPcontent",
                          attrs: {
                            config: _vm.assetLiabilityTableData,
                            id: _vm.assetLiabilityView,
                          },
                        }),
                        _vm.chartSeriesPayment
                          ? _c("highchart", {
                              key: "payment",
                              staticClass: "LCPcontent",
                              attrs: {
                                options: _vm.chartOptionsPayment,
                                "series-options": _vm.chartSeriesPayment,
                              },
                            })
                          : _c("vue-markdown", { staticClass: "LCPcontent" }, [
                              _vm._v(
                                _vm._s(_vm.config.noRecoveryPlanPaymentText)
                              ),
                            ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "col-md-4 partialPageCardRight" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.blocked,
                  expression: "blocked",
                },
              ],
              attrs: { id: "blockedContent" },
            },
            [
              _c("div", { staticClass: "text" }, [
                _c("span", { staticClass: "icon icon-Lock" }),
                _vm._v(" " + _vm._s(_vm.blockedContentText) + " "),
              ]),
            ]
          ),
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-header-with-action" },
              [_vm._m(0), _c("reset-inputs")],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-12 input-container" },
              _vm._l(_vm.inputs, function (input, key) {
                return _c("input-wrapper", {
                  key: key,
                  attrs: { inputTemplate: input },
                })
              }),
              1
            ),
          ]),
        ]),
      ])
    : _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } },
          [
            _c(
              "div",
              { staticClass: "card" },
              [
                _c("div", { staticClass: "card-header" }, [
                  _c("h1", [_vm._v(_vm._s(_vm.config.recoveryPlanName))]),
                ]),
                _c("vue-markdown", {
                  staticClass: "LCPcontent",
                  attrs: { source: _vm.pageNotAvailableText },
                }),
              ],
              1
            ),
          ]
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }