var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "body-adjustment" },
    _vm._l(_vm.contactItems, function (info, i) {
      return _c(
        "div",
        { key: i, staticClass: "col-md-4 col-sm-12 item" },
        [_c("contact-tile", { attrs: { contactInfo: info } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }