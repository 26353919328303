var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row full-height", attrs: { id: "schemeSelecter" } },
    [
      _c("div", { staticClass: "card-container" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", [
                _vm._m(0),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12 col-xs-12" }, [
                    _vm._m(1),
                    _c(
                      "div",
                      { staticClass: "card-content col-md-8 col-xs-12" },
                      [
                        _c(
                          "div",
                          { staticClass: "return" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "dashboard" } },
                              [
                                _vm._v("Return to site "),
                                _c("icon", {
                                  staticClass: "icon",
                                  attrs: { name: "arrow-right" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-12 col-xs-12 help-support" },
                    [
                      _c("div", { staticClass: "col-md-1 empty" }, [
                        _vm._v(" "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-content col-md-5 col-xs-12" },
                        [
                          _c("div", { staticClass: "helpLink card-1" }, [
                            _vm._m(2),
                            _c(
                              "div",
                              { staticClass: "card-content email-content" },
                              [
                                _c("p", [_vm._v("Visualise Helpdesk")]),
                                _c(
                                  "a",
                                  [
                                    _c("icon", { attrs: { name: "envelope" } }),
                                    _vm._v(" visualiseenquires@lcp.uk.com"),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "card-content col-md-5 col-xs-12" },
                        [
                          _c("div", { staticClass: "helpLink card-1" }, [
                            _vm._m(3),
                            _c(
                              "div",
                              { staticClass: "card-content email-content" },
                              [
                                _c("p", [_vm._v("Visualise Support")]),
                                _c(
                                  "a",
                                  [
                                    _c("icon", { attrs: { name: "envelope" } }),
                                    _vm._v(" visualisesupport@lcp.uk.com"),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "col-md-1 empty" }, [
                        _vm._v(" "),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header row" }, [
      _c("div", { staticClass: "col-md-12 col-xs-12" }, [
        _c("div", { staticClass: "col-md-8 col-xs-8" }, [
          _c("h1", [_vm._v("Visualise Status")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-content col-md-8 col-xs-12" }, [
      _c("div", { staticClass: "helpLink message" }, [
        _c("p", [
          _vm._v(
            "A problem has occurred on this web site. Please try again. If this error continues, please contact support"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header row" }, [
      _c("div", { staticClass: "col-md-12 col-xs-12 email-head" }, [
        _c("h1", [_vm._v("Scheme Related Enquires")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header row" }, [
      _c("div", { staticClass: "col-md-12 col-xs-12 email-head" }, [
        _c("h1", [_vm._v("Technical Problems")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }