var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card-header-inline",
      class: {
        onResponsiveViewChange: _vm.matchesResponsive(_vm.responsiveSize),
        noTitle: _vm.title == null && _vm.dropdownTitle == null,
      },
    },
    [
      _vm.title ? _c("h1", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
      _vm.dropdownTitle != null
        ? _c("dropdown-title", {
            attrs: {
              dropdownPrefix: _vm.dropdownTitle.prefix,
              inputTemplate: _vm.dropdownTitle.inputTemplate,
              "value-override": _vm.titleValueOverride,
            },
            on: { currentvalue: _vm.dropdownChange },
          })
        : _vm._e(),
      _vm.matchesResponsive(_vm.responsiveSize)
        ? _c("input-wrapper", {
            attrs: { inputTemplate: _vm.tabsInput, "value-override": null },
            on: { currentvalue: _vm.updateToggleView },
          })
        : _c(
            "ul",
            {
              staticClass: "inline nav nav-tabs",
              attrs: { id: "myTab-6", role: "tablist" },
            },
            [
              _vm._l(_vm.tabs, function (tabName) {
                return _c("li", { key: tabName, staticClass: "nav-item" }, [
                  _vm.showTabs
                    ? _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          class: _vm.toggleView === tabName ? "active" : "",
                          on: {
                            click: function ($event) {
                              return _vm.updateToggleView(tabName)
                            },
                          },
                        },
                        [_vm._v(_vm._s(tabName))]
                      )
                    : _vm._e(),
                ])
              }),
              _vm.includeReset
                ? _c(
                    "li",
                    [
                      _c("reset-inputs", {
                        staticClass: "action",
                        attrs: { absolute: false },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }