var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", [
      _vm._v(
        "This privacy policy (together with the LCP Visualise terms and conditions) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed. By agreeing to the LCP Visualise terms and conditions, you are also accepting this privacy policy, including our use of cookies."
      ),
    ]),
    _c("p", [
      _vm._v(
        "In this policy “Data Protection Law” means all applicable laws and regulations from time to time in force relating to data protection and privacy, including (to the extent applicable) the EU General Data Protection Regulation."
      ),
    ]),
    _c("p", [
      _vm._v(
        "For the purposes of Data Protection Law, the data controller is Lane Clark & Peacock LLP. Please see “Information about us” in the LCP Visualise terms and conditions for further information about the data controller."
      ),
    ]),
    _c("p", [
      _vm._v(
        "Capitalised or other defined terms used in this privacy policy have the meanings given to them in the LCP Visualise terms and conditions."
      ),
    ]),
    _c("h3", [_vm._v("Personal data that we may collect from you")]),
    _c("p", [
      _vm._v("We may collect and process the following data about you:"),
    ]),
    _vm._m(0),
    _c("h3", [_vm._v("How we use your personal data")]),
    _c("p", [
      _vm._v(
        "We process personal data for certain legitimate business purposes, which include some or all of the following:"
      ),
    ]),
    _vm._m(1),
    _c("p", [
      _vm._v(
        "We process your personal data for these purposes because it is in both our and our Client’s interest to do so, as it enhances the services that we provide. We also consider that we are processing your personal data in ways in which you would reasonably expect."
      ),
    ]),
    _c("p", [
      _vm._v(
        "We will not disclose your personal data to any other party or for any other reason other than as set out in this privacy policy without your prior consent."
      ),
    ]),
    _c("h3", [_vm._v("Retention of your personal data")]),
    _c("p", [
      _vm._v(
        "We will retain your personal data for so long as the purpose you have provided it for still exists unless a longer retention period is required or permitted by law."
      ),
    ]),
    _c("h3", [_vm._v("Your rights in respect of your personal data")]),
    _c("p", [_vm._v("You have the right to:")]),
    _vm._m(2),
    _c("p", [
      _vm._v(
        "If you wish to exercise any of these rights, please contact us using the contact details listed at the bottom of this privacy policy. We will aim to respond to any request received from you within one month of receipt."
      ),
    ]),
    _c("h3", [_vm._v("Automated decision making")]),
    _c("p", [
      _vm._v(
        "The only circumstance in which we use your personal data for the purpose of profiling (automated decision making) is through cookies on this website."
      ),
    ]),
    _c("h3", [_vm._v("Cookies")]),
    _c("p", [_vm._v("We use the following types of cookies on this website:")]),
    _c("div", { staticClass: "table" }, [
      _vm.$mq === "lg"
        ? _c("div", { staticClass: "tr" }, [
            _c("div", { staticClass: "td headerRow" }, [_vm._v("Cookie")]),
            _c("div", { staticClass: "td headerRow" }, [_vm._v("Purpose")]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "tr" }, [
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Cookie")])
            : _vm._e(),
          _vm._v(" Strictly necessary cookies "),
        ]),
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Purpose")])
            : _vm._e(),
          _vm._v(
            " These are cookies that are required for the operation of this website. They include, for example, cookies that enable you to log into secure areas of the website. "
          ),
        ]),
      ]),
      _c("div", { staticClass: "tr" }, [
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Cookie")])
            : _vm._e(),
          _vm._v(" Analytical/performance cookies "),
        ]),
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Purpose")])
            : _vm._e(),
          _vm._v(
            " These are cookies that allow us to recognise and count the number of visitors and to see how visitors move around this website when they are using it. This helps us to improve the way this website works, for example, by ensuring that users are finding what they are looking for easily. "
          ),
        ]),
      ]),
    ]),
    _vm._m(3),
    _c("p", [
      _vm._v(
        "The table below gives more information about the individual cookies we use and the purposes for which we use them."
      ),
    ]),
    _c("div", { staticClass: "table" }, [
      _vm.$mq === "lg"
        ? _c("div", { staticClass: "tr" }, [
            _c("div", { staticClass: "td headerRow" }, [_vm._v("Cookie")]),
            _c("div", { staticClass: "td headerRow" }, [_vm._v("Name")]),
            _c("div", { staticClass: "td headerRow" }, [_vm._v("Purpose")]),
            _c("div", { staticClass: "td headerRow" }, [_vm._v("More info")]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "tr" }, [
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Cookie")])
            : _vm._e(),
          _vm._v("Google Analytics"),
        ]),
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Name")])
            : _vm._e(),
          _vm._v("_ga"),
        ]),
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Purpose")])
            : _vm._e(),
          _vm._v(
            " Like many services, Google Analytics uses first-party cookies to track visitor interactions. These cookies are used to store information, such as what time the current visit occurred, whether the visitor has been to the site before, and what site referred the visitor to the web page. Browsers do not share first-party cookies across domains. "
          ),
        ]),
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("More info")])
            : _vm._e(),
          _c(
            "a",
            {
              attrs: {
                href: "https://policies.google.com/technologies/cookies?hl=en",
                target: "_blank",
              },
            },
            [_vm._v("Google cookie usage")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "tr" }, [
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Cookie")])
            : _vm._e(),
          _vm._v("Site form cookie"),
        ]),
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Name")])
            : _vm._e(),
          _vm._v("ASP.NET_SessionId"),
        ]),
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Purpose")])
            : _vm._e(),
          _vm._v(
            "This cookie is used to store user specific data associated with login credentials."
          ),
        ]),
        _c("div", { staticClass: "td" }),
      ]),
      _c("div", { staticClass: "tr" }, [
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Cookie")])
            : _vm._e(),
          _vm._v("Authentication"),
        ]),
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Name")])
            : _vm._e(),
          _vm._v("FedAuth, FedAuth1"),
        ]),
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Purpose")])
            : _vm._e(),
          _vm._v(
            "This cookie contains the security token created during the login process."
          ),
        ]),
        _c("div", { staticClass: "td" }),
      ]),
      _c("div", { staticClass: "tr" }, [
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Cookie")])
            : _vm._e(),
          _vm._v("Anti-forgery token"),
        ]),
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Name")])
            : _vm._e(),
          _vm._v("_RequestVerificationToken"),
        ]),
        _c("div", { staticClass: "td" }, [
          _vm.$mq !== "lg"
            ? _c("div", { staticClass: "title" }, [_vm._v("Purpose")])
            : _vm._e(),
          _vm._v("This cookie is used to prevent cross-site request forgery."),
        ]),
        _c("div", { staticClass: "td" }),
      ]),
    ]),
    _c("h3", [_vm._v("Complaints")]),
    _c("p", [
      _vm._v(
        "We aim to meet the highest standards when collecting and using your personal data. However, if you believe we are not meeting these standards and wish to make a complaint, please contact our Data Protection Officer using the contact details listed below."
      ),
    ]),
    _vm._m(4),
    _c("h3", [_vm._v("How to contact us?")]),
    _vm._m(5),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Personal data that you or the Client provides to us in connection with our provision of professional services to the Client and the provision of access to this website. The information that you or the Client may give us may include your name, job title, email address, office address, phone number and other personal data necessary for us to provide services to the Client."
        ),
      ]),
      _c("li", [
        _vm._v("Your username and password for access to this website."),
      ]),
      _c("li", [
        _vm._v(
          "Information we collect about you when you use this website, such as technical information and information about your visit."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("To provide the Client with professional services.")]),
      _c("li", [
        _vm._v(
          "To provide you with access to LCP Visualise and notify you of changes to the service."
        ),
      ]),
      _c("li", [
        _vm._v(
          "To administer and improve the performance or security of this website."
        ),
      ]),
      _c("li", [
        _vm._v(
          "To send you information relating to recent developments, seminars and events we are running and the services we offer. We may disclose your personal data to third parties with whom we run events for the purposes of inviting you to such events. Please contact us if you no longer wish to receive such information or invitations using those contact details at the bottom of this privacy policy."
        ),
      ]),
      _c("li", [_vm._v("For any other purpose required or permitted by law.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("a copy of your personal data that we hold;")]),
      _c("li", [
        _vm._v("have your data corrected if it is inaccurate or incomplete;"),
      ]),
      _c("li", [
        _vm._v(
          "have your data deleted or removed if it is no longer necessary for the purpose for which it was obtained;"
        ),
      ]),
      _c("li", [
        _vm._v(
          "request that the way in which we use your data is restricted to certain uses which you may specify;"
        ),
      ]),
      _c("li", [
        _vm._v(
          "object to us claiming that we have a legitimate interest in processing your data; and"
        ),
      ]),
      _c("li", [
        _vm._v(
          "request that we provide your personal data that we hold to you, so that you may reuse your data for your own purposes."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Your browser will allow you to restrict, block or remove any cookies set by this website. Details of this functionality will vary by browser but can usually be found in the browser help section. The website "
      ),
      _c(
        "a",
        { attrs: { href: "http://www.aboutcookies.org", target: "_blank" } },
        [_vm._v("www.aboutcookies.org")]
      ),
      _vm._v(
        " contains more details about cookies and options for dealing with them."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "If you are not satisfied with our response you have the right to complain to the "
      ),
      _c("a", { attrs: { href: "https://ico.org.uk/", target: "_blank" } }, [
        _vm._v("Information Commissioner’s Office"),
      ]),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "If you would like further information about our privacy policy or how to request your personal data you can email us at data.protection@lcp.uk.com or write to:"
      ),
      _c("br"),
      _vm._v("The Data Protection Officer"),
      _c("br"),
      _vm._v("95 Wigmore Street"),
      _c("br"),
      _vm._v("London"),
      _c("br"),
      _vm._v(" W1U 1DQ "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }