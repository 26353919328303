var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modalBg" }, [
    _c("div", { staticClass: "editModal" }, [
      _c("div", { staticClass: "modalHeader" }, [
        _c(
          "div",
          {
            staticClass: "closeModal",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_c("span", { staticClass: "icon icon-x-circle" })]
        ),
        _vm._v("Download files "),
      ]),
      _c(
        "div",
        { staticClass: "LCPcontent" },
        [
          _c("lcp-table", {
            staticClass: "LCPcontent",
            attrs: { config: _vm.tableData },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }