var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body-adjustment" }, [
    _c(
      "div",
      {
        directives: [{ name: "masonry", rawName: "v-masonry" }],
        attrs: {
          "origin-left": "true",
          "transition-duration": "1s",
          "item-selector": ".item",
          "column-width": "#gridSizer",
        },
      },
      [
        _c("div", { staticClass: "col-md-2", attrs: { id: "gridSizer" } }),
        _vm._l(_vm.infoArray, function (info, i) {
          return _c(
            "div",
            {
              directives: [{ name: "masonry-tile", rawName: "v-masonry-tile" }],
              key: i,
              staticClass: "col-md-4 col-sm-12 item",
            },
            [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("h1", [_vm._v(_vm._s(info.name))]),
                ]),
                _c(
                  "div",
                  { staticClass: "card-content" },
                  [_c("vue-markdown", { attrs: { source: info.description } })],
                  1
                ),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }