var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "col-md-8 framed partialPageCardLeft",
        attrs: { id: "mainCard" },
      },
      [
        _c(
          "div",
          { staticClass: "card" },
          [
            _c(
              "div",
              { staticClass: "card-header" },
              [
                _c("dropdown-title", {
                  attrs: {
                    dropdownPrefix: "Covenant - ",
                    inputTemplate: _vm.inputs[_vm.sectionsKey],
                  },
                }),
              ],
              1
            ),
            _c("highchart", {
              staticClass: "LCPcontent",
              attrs: {
                options: _vm.chartOptions,
                "series-options": _vm.chartSeries,
                "loading-text": _vm.watermarkText,
                showXsChart: true,
              },
            }),
            _vm.description
              ? _c("vue-markdown", {
                  staticClass: "LCPcontent",
                  attrs: { source: _vm.description },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "col-md-4 partialPageCardRight" }, [
      _c("div", { staticClass: "card" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "col-md-12 input-container" },
          _vm._l(_vm.dynamicOutput, function (scenario, key, i) {
            return _c("lcp-button", {
              key: i,
              attrs: {
                id: key,
                text: key,
                handleClick: _vm.setScenario,
                active: _vm.activeScenario === key,
              },
            })
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h1", [_vm._v("Display options")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }