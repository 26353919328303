var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inputMargin" },
    [
      _c(
        "div",
        { staticStyle: { "overflow-y": "auto", "overflow-x": "hidden" } },
        [
          _vm.lockable
            ? _c("span", {
                staticClass: "lock icon",
                class: _vm.lockIcon,
                staticStyle: { display: "inline-block" },
                on: { click: _vm.toggleLock },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: { display: "inline-block", "margin-right": "1rem" },
            },
            [_vm._v(_vm._s(_vm.sliderDescription))]
          ),
          _c("text-input", {
            class: _vm.alignInput,
            attrs: {
              id: _vm.id,
              value: _vm.value,
              getValueAdditionalChecks: _vm.getValueWithChecks,
              displayType: _vm.config.displayType,
              relative: _vm.config.relative,
              relativeTo: _vm.config.relativeTo,
              decimalPlacesOverride: _vm.config.decimalPlacesOverride,
              locked: _vm.locked,
              lockedState: _vm.lockedState,
              lowerBound: _vm.lowerBound,
              upperBound: _vm.upperBound,
              valueChanged: _vm.valueChanged,
            },
            on: { valueUpdated: _vm.onTextValueUpdated },
          }),
        ],
        1
      ),
      _c("basicSlider", {
        attrs: {
          speed: 0,
          minIndex: _vm.minIndex,
          maxIndex: _vm.maxIndex,
          minIndexAllowed: _vm.minIndexAllowed,
          maxIndexAllowed: _vm.maxIndexAllowed,
          value: _vm.sliderIndex,
          resetValue: _vm.resetIndex,
          disabled: _vm.lockedState,
          onDragStart: _vm.startSlide,
          changed: _vm.changed,
          onDragEnd: _vm.endSlide,
          colourOverride: _vm.colourOverride,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }