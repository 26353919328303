var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageAvailable
    ? _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } },
          [
            _c(
              "div",
              { staticClass: "card" },
              [
                _c("toggle-header", {
                  attrs: {
                    tabs: _vm.tabs,
                    viewOverride: _vm.currentView,
                    title: _vm.header,
                  },
                  on: { currentView: _vm.onCurrentViewChange },
                }),
                _vm.includeHeadlineSummary &&
                _vm.currentView === _vm.headlinesView
                  ? _c("lcp-table", {
                      staticClass: "LCPcontent",
                      attrs: {
                        config: _vm.config.headlineSummaryPageData,
                        id: _vm.headlinesView,
                      },
                    })
                  : _vm._e(),
                _vm.includeProgress && _vm.currentView === _vm.progressView
                  ? _c("lcp-table", {
                      staticClass: "LCPcontent",
                      attrs: {
                        config: _vm.config.recoveryProgressPageData,
                        id: _vm.progressView,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ])
    : _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } },
          [
            _c(
              "div",
              { staticClass: "card" },
              [
                _c("div", { staticClass: "card-header" }, [
                  _c("h1", [_vm._v(_vm._s(_vm.header))]),
                ]),
                _c("vue-markdown", {
                  staticClass: "LCPcontent",
                  attrs: { source: _vm.pageNotAvailableText },
                }),
              ],
              1
            ),
          ]
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }