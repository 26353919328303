var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "col-md-8 framed partialPageCardLeft",
        attrs: { id: "mainCard" },
      },
      [
        _c(
          "div",
          { staticClass: "card" },
          [
            _vm._m(0),
            _c("lcp-table", {
              staticClass: "LCPcontent",
              attrs: { config: _vm.tableData },
            }),
            _c("toggle-header", {
              attrs: {
                tabs: _vm.resultTabs,
                viewOverride: _vm.currentResultsView,
              },
              on: { currentView: _vm.onResultsViewUpdate },
            }),
            _vm.includeAssetPieCharts &&
            _vm.currentResultsView === _vm.assetHoldingsView
              ? _c("investment-pie-chart", {
                  staticClass: "LCPcontent",
                  attrs: {
                    config: _vm.pieConfig,
                    dynamicData: _vm.dynamicOutput.allocation,
                  },
                })
              : _vm._e(),
            _vm.currentResultsView === _vm.riskReturnView
              ? _c("risk-return-chart", {
                  staticClass: "LCPcontent",
                  attrs: {
                    config: _vm.config,
                    dynamicOutput: _vm.dynamicOutput,
                    possibleOptionsData: _vm.possibleOptionsForSliderData,
                  },
                })
              : _vm._e(),
            _vm.currentResultsView === _vm.riskAttributionView
              ? _c("risk-attribution-chart", {
                  key: "investmentRisk",
                  staticClass: "LCPcontent",
                  attrs: {
                    config: _vm.config.riskAttributionChartConfig,
                    initialData: _vm.config.initialResult.attributionData,
                    dynamicData: _vm.dynamicOutput.attributionData,
                  },
                })
              : _vm._e(),
            _vm.currentResultsView === _vm.liquidityView
              ? _c("liquidity-chart", {
                  staticClass: "LCPcontent",
                  attrs: {
                    "dynamic-data": _vm.dynamicOutput.liquidityChartData,
                  },
                })
              : _vm._e(),
            _c("vue-markdown", {
              staticClass: "LCPcontent",
              attrs: { source: _vm.config.riskDefinition },
            }),
            _vm.config.includeHedgingMetricsInSummary
              ? _c("vue-markdown", { staticClass: "LCPcontent" }, [
                  _vm._v(
                    "The estimated interest rate hedge ratio and inflation hedge ratio indicate what proportion of the change in the " +
                      _vm._s(_vm.config.riskReferenceLiabilityDisplayName) +
                      " is matched by the investment strategy for a 0.01% pa move in long-term interest rate and inflation expectations respectively."
                  ),
                ])
              : _vm._e(),
            _vm.$mq != "xs" &&
            _vm.config.covenantRiskVReturnDescriptionShown &&
            _vm.currentResultsView == _vm.riskReturnView
              ? _c("vue-markdown", {
                  staticClass: "LCPcontent",
                  attrs: { source: _vm.config.covenantRiskVReturnDescription },
                })
              : _vm._e(),
            _vm.$mq != "xs" &&
            _vm.config.covenantRiskAttributionDescriptionShown &&
            _vm.currentResultsView === _vm.riskAttributionView
              ? _c("vue-markdown", {
                  staticClass: "LCPcontent",
                  attrs: {
                    source: _vm.config.covenantRiskAttributionDescription,
                  },
                })
              : _vm._e(),
            _vm.config.climateRiskTileShown
              ? _c("div", { staticClass: "LCPcontent" }, [
                  _vm._v(
                    " Systemic climate risk is not shown in the above – this can be much bigger than the Value at Risk due to investment and longevity factors. Please click "
                  ),
                  _c("a", { on: { click: _vm.navigateToSensitivityPage } }, [
                    _vm._v("here"),
                  ]),
                  _vm._v(" to see how this might affect your scheme. "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "col-md-4 partialPageCardRight" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("toggle-header", {
            attrs: {
              tabs: _vm.sliderTabs,
              title: "Strategy",
              includeReset: true,
            },
            on: { currentView: _vm.onSliderViewUpdate },
          }),
          _c(
            "div",
            { staticClass: "col-md-12 input-container" },
            _vm._l(_vm.currentSliderInputs, function (input, key) {
              return _c("input-wrapper", {
                key: key,
                attrs: {
                  inputTemplate: input,
                  "value-override": _vm.inputOverrides(key),
                },
                on: {
                  currentvalue: _vm.inputsChanged,
                  currentId: _vm.setCurrentSlide,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h1", [_vm._v("Portfolio analysis")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }