import { render, staticRenderFns } from "./pageAccounting.vue?vue&type=template&id=00b6b920&scoped=true&"
import script from "./pageAccounting.vue?vue&type=script&lang=js&"
export * from "./pageAccounting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00b6b920",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/Lcp.VisualisePro/Lcp.VisualisePro.WebVue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00b6b920')) {
      api.createRecord('00b6b920', component.options)
    } else {
      api.reload('00b6b920', component.options)
    }
    module.hot.accept("./pageAccounting.vue?vue&type=template&id=00b6b920&scoped=true&", function () {
      api.rerender('00b6b920', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/schemeDisplays/accounting/pageAccounting.vue"
export default component.exports