var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "basicTileMetricTable cashflowTable" },
    [
      _vm._l(_vm.currentData.metrics, function (metric, mainIndex) {
        return _c("tr", { key: mainIndex }, [
          _c("td", [_vm._v(_vm._s(metric.description))]),
          _c("td", [_vm._v(_vm._s(_vm.percentage(metric.value)))]),
          _c("td", [_vm._v(_vm._s(_vm.getValueStringFromMetric(metric)))]),
        ])
      }),
      _c("tr", [
        _c("td"),
        _c("td", { staticClass: "borderTop" }),
        _c("td", { staticClass: "borderTop" }, [
          _vm._v(_vm._s(_vm.getValueStringFromMetric(_vm.currentData.total))),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }