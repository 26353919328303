var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.config.shockPageTop
        ? _c("vue-markdown", {
            staticClass: "LCPcontent",
            attrs: { source: _vm.config.shockPageTop },
          })
        : _vm._e(),
      _c("lcp-table", {
        staticClass: "LCPcontent",
        attrs: { config: _vm.tableConfig },
      }),
      _vm.config.shockPageBottom
        ? _c("vue-markdown", {
            staticClass: "LCPcontent",
            attrs: { source: _vm.config.shockPageBottom },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }