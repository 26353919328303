var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("standardMetrics", {
    attrs: {
      id: _vm.id,
      tileData: _vm.tileData.metricsByView[_vm.loopMetric],
      chartData: _vm.chartData,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }