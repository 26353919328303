// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/images/cross-pattern-fade-bottom-right-wide.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*Marine*/\n/*Grapefruit*/\n/*Watermelon*/\n/*Grey*/\n/*Lime*/\n/*Forest green*/\n/*Aqua*/\n/*Lilac*/\n/*Pale pink*/\n/*Cherry*/\n.viewScheme, .viewNoScheme {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: right bottom;\n  background-repeat: no-repeat;\n}\n.viewNoScheme {\n  background-color: rgb(0, 47, 95);\n  color: white;\n  height: 100%;\n  overflow: auto;\n  padding: 50px 120px 0px 120px;\n}\n@media (max-width: 1199.98px) {\n.viewNoScheme {\n    padding: 30px 20px 0px 20px;\n}\n}\n@media (max-width: 767.98px) {\n.viewNoScheme {\n    padding: 20px 20px 0px 20px;\n}\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
