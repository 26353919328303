import { render, staticRenderFns } from "./deriskingCollateralShocksTab.vue?vue&type=template&id=56c62b46&"
import script from "./deriskingCollateralShocksTab.vue?vue&type=script&lang=js&"
export * from "./deriskingCollateralShocksTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/Lcp.VisualisePro/Lcp.VisualisePro.WebVue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56c62b46')) {
      api.createRecord('56c62b46', component.options)
    } else {
      api.reload('56c62b46', component.options)
    }
    module.hot.accept("./deriskingCollateralShocksTab.vue?vue&type=template&id=56c62b46&", function () {
      api.rerender('56c62b46', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/schemeDisplays/derisking/deriskingCollateralShocksTab.vue"
export default component.exports