var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("highchart", {
    key: "collateralOverTime",
    staticClass: "LCPcontent",
    attrs: { options: _vm.chartOptions, "series-options": _vm.chartSeries },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }