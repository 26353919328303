var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("standardMetrics", {
    staticClass: "wideValues",
    attrs: {
      id: _vm.id,
      tileData: _vm.tileDataToShow,
      headers: ["Trigger point", "Status"],
      rowCountOverride: _vm.maxRows,
      coverText: _vm.coverText,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }