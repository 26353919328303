var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } }, [
      _c("div", { staticClass: "card" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "col-md-12 LCPcontent" },
          _vm._l(_vm.availableReports, function (report, index) {
            return _c(
              "div",
              { key: index },
              [
                _c("download-report", {
                  attrs: { btnText: report, reportType: report },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h1", [_vm._v("Reporting")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }