var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.includeTable
    ? _c("lcp-table", {
        staticClass: "LCPcontent",
        attrs: { config: _vm.combinedTable },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }