import { render, staticRenderFns } from "./note.vue?vue&type=template&id=7de08f00&scoped=true&"
import script from "./note.vue?vue&type=script&lang=js&"
export * from "./note.vue?vue&type=script&lang=js&"
import style0 from "./note.vue?vue&type=style&index=0&id=7de08f00&scoped=true&lang=scss&"
import style1 from "./note.vue?vue&type=style&index=1&id=7de08f00&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7de08f00",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/Lcp.VisualisePro/Lcp.VisualisePro.WebVue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7de08f00')) {
      api.createRecord('7de08f00', component.options)
    } else {
      api.reload('7de08f00', component.options)
    }
    module.hot.accept("./note.vue?vue&type=template&id=7de08f00&scoped=true&", function () {
      api.rerender('7de08f00', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/tiles/note.vue"
export default component.exports