var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdownIconWrapper" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.toolTip,
            expression: "toolTip",
          },
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.onClickOutside,
            expression: "onClickOutside",
          },
        ],
        staticClass: "iconWrapper",
        class: [_vm.additionalIconWrapperClass, { active: _vm.showDropdown }],
        on: { click: _vm.mainClick },
      },
      [_c("span", { staticClass: "icon", class: [_vm.iconString] })]
    ),
    _vm.showDropdown
      ? _c(
          "div",
          { staticClass: "dropdown", class: _vm.additionalDropdownClass },
          [
            _c(
              "ul",
              _vm._l(_vm.subItems, function (item, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    on: {
                      click: function ($event) {
                        return _vm.onSubItemClick(item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.text) + " ")]
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }