var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.inputTemplate.type, {
    tag: "component",
    attrs: {
      "line-height": 20,
      inputTemplate: _vm.inputTemplate,
      "value-override": _vm.valueOverride,
      "dynamic-label": _vm.dynamicLabel,
    },
    on: {
      currentId: _vm.emitCurrentId,
      currentvalue: _vm.emitCurrentValue,
      updatePageProcess: _vm.emitUpdatePageProcess,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }