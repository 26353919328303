var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "table",
      { staticClass: "basicTileMetricTable marketsTable" },
      [
        _c("tr", [
          _c("td"),
          _c("td"),
          _c("td", { attrs: { colspan: "2" } }, [
            _vm._v(_vm._s("since " + _vm.tileData.dateFrom)),
          ]),
        ]),
        _vm._m(0),
        _vm._l(_vm.tileData.data, function (item) {
          return _c("tr", { key: item.description }, [
            _c("td", [
              _vm._v(_vm._s(item.description) + " "),
              item.source !== ""
                ? _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: item.source,
                        expression: "item.source",
                      },
                    ],
                    staticClass: "icon icon-info",
                  })
                : _vm._e(),
            ]),
            _c("td", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(
                _vm._s(_vm.getScaledValueString(item.value, item.scalingType))
              ),
            ]),
            _c("td", [
              _vm._v(
                _vm._s(_vm.getScaledValueString(item.yearLow, item.scalingType))
              ),
            ]),
            _c("td", [
              _vm._v(
                _vm._s(
                  _vm.getScaledValueString(item.yearHigh, item.scalingType)
                )
              ),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td"),
      _c("td", { staticClass: "borderBottom" }, [_vm._v("now")]),
      _c("td", { staticClass: "borderBottom borderTop" }, [_vm._v("low")]),
      _c("td", { staticClass: "borderBottom" }, [_vm._v("high")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }