var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "loaderWrapper" } }, [
    _c("div", { staticClass: "centralised" }, [
      _c("img", { staticClass: "animatedLogo", attrs: { src: _vm.logo } }),
      _c("h1", { staticClass: "schemeTitle" }, [
        _vm._v(_vm._s(_vm.loadingText)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }