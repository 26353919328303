var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        class: [
          _vm.config.includeMarketData
            ? "col-md-8 partialPageCardLeft"
            : "col-md-12",
          "framed",
        ],
      },
      [
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("toggle-header", {
              attrs: {
                tabs: _vm.tabs,
                viewOverride: _vm.currentView,
                dropdownTitle: _vm.dropDownTitle,
              },
              on: { currentView: _vm.onCurrentViewChange },
            }),
            _vm.currentView === _vm.historyView
              ? _c(
                  "div",
                  [
                    _vm.config.buyoutEstimateSelectable
                      ? _c(
                          "div",
                          { staticClass: "LCPcontent" },
                          [
                            _c("vue-markdown", [
                              _vm._v(
                                "The indicative buy-out estimate reflects possible pricing from a competitive, well-prepared insurance process with engaged insurers."
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("lcp-table", {
                      staticClass: "LCPcontent",
                      attrs: { config: _vm.tableDataNew },
                    }),
                    _c("highchart", {
                      staticClass: "LCPcontent",
                      attrs: {
                        options: _vm.chartOptions,
                        "series-options": _vm.chartSeries,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.currentView === _vm.aomView
              ? _c(
                  "div",
                  [
                    _c("aom-tab", {
                      attrs: {
                        config: _vm.config,
                        inputs: _vm.inputs,
                        dynamicData: _vm.dynamicOutput.aomData,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("slider-with-periods", {
              attrs: { dateInputs: _vm.dateInputs },
            }),
          ],
          1
        ),
      ]
    ),
    _vm.config.includeMarketData
      ? _c("div", { staticClass: "col-md-4 partialPageCardRight" }, [
          _c(
            "div",
            { staticClass: "card" },
            [
              _vm._m(0),
              _c("lcp-table", {
                staticClass: "col-md-12 input-container",
                attrs: {
                  config: _vm.assetMarketTableConfig,
                  responsiveSize: "xs",
                },
              }),
              _vm._m(1),
              _c("lcp-table", {
                staticClass: "col-md-12 input-container",
                attrs: { config: _vm.ratesTableConfig, responsiveSize: "xs" },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h1", [_vm._v("Market returns over period")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h1", [_vm._v("Rates over period")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }