var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input--div", style: _vm.divStyles },
    [
      _vm.type === "text"
        ? [
            _c("input", {
              ref: "text",
              staticClass: "input--box",
              class: {
                "input--center": _vm.center,
                "input--error": _vm.incorrectInput,
                large: _vm.large,
              },
              style: _vm.inputStyles,
              attrs: { type: "text", id: _vm.id },
              domProps: { value: _vm.inputValue },
              on: {
                input: function ($event) {
                  return _vm.emitValue($event.target.value)
                },
                focus: function ($event) {
                  return _vm.setStyle()
                },
                blur: function ($event) {
                  return _vm.setStyle()
                },
              },
            }),
          ]
        : _vm.type === "number"
        ? [
            _c("input", {
              ref: "number",
              staticClass: "input--box",
              class: {
                "input--center": _vm.center,
                "input--error": _vm.incorrectInput,
                large: _vm.large,
              },
              style: _vm.inputStyles,
              attrs: { type: "text", id: _vm.id },
              domProps: { value: _vm.inputValue },
              on: {
                input: function ($event) {
                  return _vm.emitValue($event.target.value)
                },
                keyup: function ($event) {
                  return _vm.convertToLocaleString($event.target.value)
                },
                blur: function ($event) {
                  return _vm.setStyle()
                },
                focus: function ($event) {
                  return _vm.setStyle()
                },
              },
            }),
          ]
        : _vm._e(),
      _vm.label !== undefined
        ? _c(
            "label",
            {
              staticClass: "input--label",
              class: {
                "input--center": _vm.center,
                "not-empty": _vm.notEmpty,
                hover: _vm.labelHover || _vm.notEmpty,
                large: _vm.large,
              },
              style: _vm.labelStyles,
              attrs: { for: _vm.id },
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
        : _vm._e(),
      _vm.icon !== undefined
        ? _c(
            "label",
            {
              staticClass: "input--icon",
              class: { large: _vm.large },
              style: _vm.iconStyles,
              attrs: { for: _vm.id },
            },
            [_c("i", { class: ["icon", "icon-" + _vm.icon] })]
          )
        : _vm._e(),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.incorrectInput,
              expression: "incorrectInput",
            },
          ],
          staticClass: "input--error",
          class: { "input--center": _vm.center },
        },
        [_vm._v(_vm._s(_vm.errorStatement))]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }