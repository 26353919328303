var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mainSection" },
    [
      _c("sidenav"),
      _c(
        "div",
        { staticClass: "schemePageView viewScheme" },
        [
          _c(
            "div",
            { staticClass: "routerViewContainer" },
            [
              _vm.showPage && _vm.caveat
                ? _c(
                    "div",
                    [
                      _c("vue-markdown", {
                        staticClass: "card caveatCard",
                        attrs: { source: _vm.caveat },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showPage ? _c("router-view") : _vm._e(),
            ],
            1
          ),
          _vm.showSchemeLoader ? _c("mini-loader") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }