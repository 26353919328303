var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.chartAvailable
    ? _c("div", [
        _c(
          "div",
          { staticClass: "LCPcontent" },
          [
            _c("highchart", {
              key: "waterfallChart",
              staticClass: "LCPcontent",
              attrs: {
                options: _vm.chartConfig,
                "series-options": _vm.chartSeries,
              },
            }),
            _c(
              "div",
              { staticClass: "controls" },
              [
                _c("input-wrapper", {
                  attrs: { inputTemplate: _vm.getAomRadioInput() },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "padding-top": "10px" } },
          [
            _c("lcp-table", {
              staticClass: "LCPcontent",
              attrs: { config: _vm.tableConfig },
            }),
          ],
          1
        ),
      ])
    : _c(
        "div",
        [
          _c("vue-markdown", {
            staticClass: "LCPcontent",
            attrs: { source: _vm.chartNotAvailableText },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }