var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "viewNoScheme" },
    [
      _c(
        "div",
        { staticClass: "card-header-large" },
        [
          !_vm.lcpUser
            ? _c("h1", [_vm._v("Select scheme")])
            : _c("input-component", {
                attrs: {
                  id: "searchInput",
                  label: "Filter your schemes",
                  "label-hover": "",
                  type: "text",
                  "icon-color": "white",
                  defaultUnderline: "white",
                  "underline-color": "white",
                  width: "350px",
                  icon: "search",
                  large: true,
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
        ],
        1
      ),
      _vm.selectedCount === 0
        ? _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm._v(_vm._s(_vm.noSchemesText)),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "LCPcontent" },
        _vm._l(_vm.schemeList, function (scheme) {
          return _c("lcp-button", {
            key: scheme.id,
            staticClass: "buttonOverrides",
            attrs: {
              text: scheme.name,
              handleClick: _vm.navigateTo(scheme.id),
            },
          })
        }),
        1
      ),
      _c("copyright", { attrs: { colorTheme: "dark" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }