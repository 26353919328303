var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      !_vm.showShemeLoader
        ? _c("header-section", { ref: "headerSection" })
        : _vm._e(),
      !_vm.showShemeLoader
        ? _c(
            "div",
            { staticClass: "routerView" },
            [
              _vm.termsUpToDate ? _c("router-view") : _vm._e(),
              !_vm.termsUpToDate
                ? _c("agreeTerms", { staticClass: "terms" })
                : _vm._e(),
              !_vm.termsUpToDate ? _c("termsRead") : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.showShemeLoader ? _c("scheme-loader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }