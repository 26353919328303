var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "modalBg" }),
    _c(
      "div",
      { staticClass: "editModal" },
      [
        _c("div", { staticClass: "modalHeader" }, [
          _c(
            "div",
            {
              staticClass: "closeModal",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_c("span", { staticClass: "icon icon-x-circle" })]
          ),
          _vm._v("Reorder your dashboard layout "),
        ]),
        _c(
          "draggable",
          {
            attrs: { options: { group: "tiles", disabled: _vm.inTransition } },
            model: {
              value: _vm.visibleTileIds,
              callback: function ($$v) {
                _vm.visibleTileIds = $$v
              },
              expression: "visibleTileIds",
            },
          },
          _vm._l(_vm.visibleTiles, function (tile, index) {
            return _c(
              "div",
              {
                key: tile.id,
                staticClass: "draggableTile",
                style: { backgroundColor: tile.colour },
              },
              [
                _c("div", { staticClass: "details" }, [
                  _vm._v(_vm._s(tile.description)),
                ]),
                _c("div", { staticClass: "showHide" }, [
                  _c("img", {
                    attrs: { src: _vm.hideIcon },
                    on: {
                      click: function ($event) {
                        return _vm.hideTile(index)
                      },
                    },
                  }),
                ]),
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "modalHeader" }, [_vm._v("Hidden tiles")]),
        _vm._l(_vm.hiddenTiles, function (tile, index) {
          return _c(
            "div",
            {
              key: tile.id,
              staticClass: "draggableTile",
              style: { backgroundColor: tile.colour },
            },
            [
              _c("div", { staticClass: "details" }, [
                _vm._v(_vm._s(tile.description)),
              ]),
              _c("div", { staticClass: "showHide" }, [
                _c("img", {
                  attrs: { src: _vm.showIcon },
                  on: {
                    click: function ($event) {
                      return _vm.showTile(index)
                    },
                  },
                }),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }