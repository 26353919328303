var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    _vm._l(_vm.options, function (option) {
      return _c(
        "div",
        { key: option, staticClass: "radioWrapper inputMargin" },
        [
          _c("checkbox", {
            staticClass: "checkbox",
            attrs: {
              id: option,
              inputTemplate: _vm.getInputTemplate(option),
              "value-override": _vm.selected === option,
            },
            on: { toggled: _vm.radioSelect },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }