var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.contactInfo.description))]),
    ]),
    _c(
      "div",
      { staticClass: "card-content" },
      [
        _c("vue-markdown", { attrs: { source: _vm.contactInfo.name } }),
        _vm.contactInfo.personDescription !== ""
          ? _c("vue-markdown", {
              attrs: { source: _vm.contactInfo.personDescription },
            })
          : _vm._e(),
        _vm.contactInfo.number !== ""
          ? _c("vue-markdown", { attrs: { source: _vm.contactInfo.number } })
          : _vm._e(),
        _vm.contactInfo.address !== ""
          ? _c(
              "div",
              [
                _c(
                  "a",
                  {
                    staticStyle: {
                      color: "black",
                      float: "left",
                      width: "15px",
                      "margin-right": "10px",
                    },
                    attrs: { href: _vm.getHyperlink(_vm.contactInfo.address) },
                  },
                  [_c("icon", { attrs: { name: "envelope" } })],
                  1
                ),
                _c(
                  "vue-markdown",
                  { staticStyle: { float: "left", "line-height": "16px" } },
                  [
                    _vm._v(
                      "[" +
                        _vm._s(_vm.contactInfo.address) +
                        "](" +
                        _vm._s(_vm.getHyperlink(_vm.contactInfo.address)) +
                        ")"
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }