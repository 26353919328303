var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "tileMetricTableBoldLastValue wideValues" },
    [
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.currentData.mainValue.description))]),
        _c("td", [
          _vm._v(_vm._s(_vm.getValueString(_vm.currentData.mainValue))),
        ]),
      ]),
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.tileData.rangeDisplayName))]),
        _c("td", [
          _vm._v(
            _vm._s(_vm.getValueString(_vm.currentData.minRangeValue)) +
              " to " +
              _vm._s(_vm.getValueString(_vm.currentData.maxRangeValue))
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }