var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _vm._m(0),
          _vm.pageAvailable
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "LCPcontent" },
                    [
                      _c("vue-markdown", [
                        _vm._v(
                          "These numbers provide an indication of the attractiveness of a pensioner buy-in for " +
                            _vm._s(_vm.schemeDisplayName) +
                            " following a competitive tender."
                        ),
                      ]),
                      _c("vue-markdown", [
                        _vm._v(
                          "The figures are calculated by LCP using pricing information provided by the participating insurers on a non-binding basis, market pricing information obtained by LCP from analysing actual insurance quotations and scheme information held by LCP."
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("lcp-table", {
                    staticClass: "LCPcontent",
                    attrs: { config: _vm.tableData },
                  }),
                  _c("highchart", {
                    staticClass: "LCPcontent",
                    attrs: {
                      options: _vm.chartOptions,
                      "series-options": _vm.chartSeries,
                    },
                  }),
                  _c("div", { staticClass: "LCPcontent insurerLogos" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2 col-xs-6" }, [
                        _c("img", {
                          staticClass: "img-responsive",
                          attrs: { src: _vm.aviva },
                        }),
                      ]),
                      _c("div", { staticClass: "col-sm-2 col-xs-6" }, [
                        _c("img", {
                          staticClass: "img-responsive",
                          attrs: { src: _vm.just },
                        }),
                      ]),
                      _c("div", { staticClass: "col-sm-2 col-xs-6" }, [
                        _c("img", {
                          staticClass: "img-responsive",
                          attrs: { src: _vm.legalandgeneral },
                        }),
                      ]),
                      _c("div", { staticClass: "col-sm-2 col-xs-6" }, [
                        _c("img", {
                          staticClass: "img-responsive",
                          attrs: { src: _vm.pic },
                        }),
                      ]),
                      _c("div", { staticClass: "col-sm-2 col-xs-6" }, [
                        _c("img", {
                          staticClass: "img-responsive",
                          attrs: { src: _vm.rothesaylife },
                        }),
                      ]),
                      _c("div", { staticClass: "col-sm-2 col-xs-6" }, [
                        _c("img", {
                          staticClass: "img-responsive",
                          attrs: { src: _vm.scottishwidows },
                        }),
                      ]),
                    ]),
                  ]),
                  _c(
                    "vue-markdown",
                    {
                      staticClass: "LCPcontent",
                      attrs: { anchorAttributes: { target: "_blank" } },
                    },
                    [
                      _vm._v(
                        "For more information on the de-risking process please visit [our de-risking site](https://www.lcp.uk.com/pensions-benefits/buy-ins-buy-outs-longevity-swaps/)."
                      ),
                    ]
                  ),
                ],
                1
              )
            : _c("vue-markdown", {
                staticClass: "LCPcontent",
                attrs: { source: _vm.pageNotAvailableText },
              }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h1", [_vm._v("Indicative insurer pricing for pensioners")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }