var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card tile",
      class: [_vm.clickableClass, _vm.id],
      style: { backgroundColor: _vm.tileData.colour },
      on: { click: _vm.goTo },
    },
    [
      _c("div", { staticClass: "card-header tile-header" }, [
        _c("div", { style: { height: _vm.headerHeight } }, [
          _vm._v(_vm._s(_vm.tileDescription)),
        ]),
        _vm.looping
          ? _c(
              "div",
              _vm._l(_vm.loopData, function (data, index) {
                return _c(
                  "div",
                  {
                    key: _vm.tileHeaderTestHeightIdPrefix + index,
                    ref: _vm.tileHeaderTestHeightIdPrefix + index,
                    refInFor: true,
                    staticClass: "testHeightStyle",
                  },
                  [_vm._v(" " + _vm._s(data.description) + " ")]
                )
              }),
              0
            )
          : _vm._e(),
        !_vm.looping
          ? _c("span", {
              staticClass: "icon",
              class: "icon-" + _vm.tileData.icon,
            })
          : _c("span", {
              staticClass: "icon",
              class: [_vm.playState ? "icon-pause" : "icon-play"],
              on: { click: _vm.togglePlayState },
            }),
      ]),
      _c(
        "div",
        { staticClass: "tile-content" },
        [
          _vm.tileData.metricsNotAvailableText
            ? _c("div", { staticClass: "tileRow" }, [
                _vm._v(_vm._s(_vm.tileData.metricsNotAvailableText)),
              ])
            : _c(_vm.tileData.tileDesignType, {
                tag: "component",
                style: { height: _vm.componentHeight },
                attrs: {
                  id: _vm.tileData.id,
                  tileData: _vm.tileData,
                  loopMetric: _vm.loopIndex,
                },
              }),
          _vm.tileData.footnote
            ? _c("div", { staticClass: "tileRow" }, [
                _vm._v(_vm._s(_vm.tileData.footnote)),
              ])
            : _vm._e(),
          _vm.looping
            ? _c(
                "div",
                _vm._l(_vm.loopData, function (data, index) {
                  return _c(_vm.tileData.tileDesignType, {
                    key: _vm.tileDataTestHeightIdPrefix + index,
                    ref: _vm.tileDataTestHeightIdPrefix + index,
                    refInFor: true,
                    tag: "component",
                    staticClass: "testHeightStyle",
                    attrs: {
                      id: _vm.tileDataTestHeightIdPrefix + index,
                      tileData: _vm.tileData,
                      loopMetric: index,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }