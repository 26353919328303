var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modalBg" }, [
    _c(
      "div",
      { staticClass: "editModal" },
      [
        _c("div", { staticClass: "modalHeader" }, [
          _vm._v(" " + _vm._s(_vm.headerText) + " "),
          _vm.receivedData
            ? _c(
                "div",
                {
                  staticClass: "closeModal",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("span", { staticClass: "icon icon-x-circle" })]
              )
            : _vm._e(),
        ]),
        !_vm.receivedData
          ? _c("mini-loader", { staticClass: "loading" })
          : _c(
              "div",
              [
                _c("div", { staticClass: "LCPcontent" }, [
                  _c("table", [
                    _c("tr", [
                      _c(
                        "td",
                        [
                          _c("checkbox", {
                            attrs: {
                              id: "enableEmails",
                              inputTemplate: _vm.enableEmailsTemplate,
                            },
                            on: { toggled: _vm.updateEmailEnabled },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.showReplyEmailAddress
                      ? _c("tr", [
                          _c("td", [_vm._v("Reply to email address")]),
                          _c(
                            "td",
                            [
                              _c("text-input", {
                                attrs: {
                                  id: "emailAddressInput",
                                  value: _vm.replyEmailAddress,
                                  displayType: "none",
                                  textMode: true,
                                },
                                on: {
                                  valueUpdated: _vm.updateReplyEmailAddress,
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.validEmail
                            ? _c("td", { staticClass: "invalidEmail" }, [
                                _vm._v(
                                  "Emails won't be sent without a valid email address"
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("radio", {
                  attrs: {
                    id: _vm.schemeName,
                    inputTemplate: _vm.getRadioInputTemplate(),
                  },
                  on: { currentvalue: _vm.onUserSelect },
                }),
                _vm.tableData
                  ? _c("lcp-table", {
                      staticClass: "LCPcontent",
                      attrs: { config: _vm.tableData },
                      on: {
                        currentvalueAndId: _vm.updateUserMonthlyEmailFlags,
                      },
                    })
                  : _c("vue-markdown", {
                      staticClass: "LCPcontent",
                      attrs: { source: "No client login history to show." },
                    }),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }