var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageAvailable
    ? _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } },
          [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-header-with-action" },
                [_c("h1", [_vm._v(_vm._s(_vm.header))]), _c("reset-inputs")],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-12 LCPcontent" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-4" },
                      [
                        _vm.inputs[_vm.config.assetProjectionTypeId].config
                          .options.length > 1
                          ? _c("input-wrapper", {
                              attrs: {
                                inputTemplate:
                                  _vm.inputs[_vm.config.assetProjectionTypeId],
                              },
                              on: {
                                currentvalue: _vm.changeAssetProjectionType,
                              },
                            })
                          : _vm._e(),
                        _vm.config.isSantander
                          ? _c("input-wrapper", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.santanderReturnsHidden,
                                  expression: "!santanderReturnsHidden",
                                },
                              ],
                              attrs: {
                                inputTemplate:
                                  _vm.inputs[_vm.config.santanderReturnTypeId],
                              },
                            })
                          : _vm._e(),
                        !_vm.definedStrategyHidden
                          ? _c("input-wrapper", {
                              attrs: {
                                inputTemplate:
                                  _vm.inputs[
                                    _vm.config.projectedReturnStrategyTypeId
                                  ],
                              },
                            })
                          : _vm._e(),
                        !_vm.definedReturnHidden
                          ? _c("input-wrapper", {
                              attrs: {
                                inputTemplate:
                                  _vm.inputs[
                                    _vm.config.projectedReturnNumericTypeId
                                  ],
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.config.isSantander
                      ? _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-4" },
                          [
                            _c("input-wrapper", {
                              attrs: {
                                inputTemplate:
                                  _vm.inputs[
                                    _vm.config
                                      .santanderReturnContingentPaymentsEnabledId
                                  ],
                              },
                            }),
                            _c("input-wrapper", {
                              attrs: {
                                inputTemplate:
                                  _vm.inputs[
                                    _vm.config
                                      .santanderFundingContingentPaymentsEnabledId
                                  ],
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-4" },
                      [
                        _c("input-wrapper", {
                          attrs: {
                            inputTemplate:
                              _vm.inputs[_vm.config.requiredReturnTypeId],
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("lcp-table", { attrs: { config: _vm.dynamicOutput } }),
                ],
                1
              ),
            ]),
          ]
        ),
      ])
    : _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } },
          [
            _c(
              "div",
              { staticClass: "card" },
              [
                _c("div", { staticClass: "card-header" }, [
                  _c("h1", [_vm._v(_vm._s(_vm.header))]),
                ]),
                _c("vue-markdown", {
                  staticClass: "LCPcontent",
                  attrs: { source: _vm.pageNotAvailableText },
                }),
              ],
              1
            ),
          ]
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }