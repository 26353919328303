var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "buttonStyle",
      class: { active: _vm.active, disabled: _vm.disabled },
      on: { click: _vm.handleClickEvent },
    },
    [
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _vm.iconToUse
        ? _c("span", {
            staticClass: "icon",
            class: _vm.iconToUse,
            staticStyle: { "margin-left": "5px" },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }