var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "wrapper" } }, [
    _c("img", { staticClass: "animatedLogo", attrs: { src: _vm.logo404 } }),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subText" }, [
      _vm._v("This page doesn't exist. "),
      _c("a", { staticClass: "schemesLink", attrs: { href: "/userschemes" } }, [
        _vm._v("Take me back to my schemes"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }