var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("derisking-Table", {
        attrs: {
          lockTableData: _vm.lockTableData,
          tableConfig: _vm.dynamicResult.headlineResultsTable,
        },
      }),
      _c("risk-attribution-chart", {
        staticClass: "LCPcontent",
        attrs: {
          config: _vm.config.attributionConfig,
          initialData: _vm.config.attributionInitialData,
          dynamicData: _vm.dynamicResult.riskAttributionResult,
        },
      }),
      _vm.config.description
        ? _c("vue-markdown", {
            staticClass: "LCPcontent",
            attrs: { source: _vm.config.description },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }