var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c(
            "div",
            { staticClass: "card-header" },
            [_c("dropdown-title", { attrs: { inputTemplate: _vm.input } })],
            1
          ),
          _c("highchart", {
            staticClass: "LCPcontent",
            attrs: {
              options: _vm.chartOptions,
              "series-options": _vm.chartSeries,
              showXsChart: true,
            },
          }),
          _c("lcp-table", {
            staticClass: "LCPcontent",
            attrs: { config: _vm.tableData },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }