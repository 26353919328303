var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "viewNoScheme" },
    [
      _vm._m(0),
      _c("terms-wording", { staticClass: "LCPcontent" }),
      _c("copyright", { attrs: { colorTheme: "dark" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header lightBorder" }, [
      _c("h1", [_vm._v("LCP Visualise terms & conditions")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }