var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.navMessages, function (message, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "messageRow",
            style: {
              background: message.backColour,
              color: message.foreColour,
            },
          },
          [
            message.message
              ? _c("div", { staticClass: "message" }, [
                  _vm._v(_vm._s(message.message)),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "actionGroup" },
              _vm._l(message.actions, function (action, key) {
                return _c("lcp-button", {
                  key: key,
                  staticClass: "action",
                  attrs: {
                    text: action.label,
                    handleClick: () =>
                      _vm.$store
                        .dispatch(`${action.storeAction}`, message.id)
                        .then(() => {})
                        .catch(() => {}),
                  },
                })
              }),
              1
            ),
          ]
        )
      }),
      _c(
        "div",
        { staticClass: "headerRow" },
        [
          _c("dropdown-icon", {
            staticClass: "showMobile",
            class: { hasNoScheme: _vm.schemeName == null },
            attrs: {
              "tool-tip": "Show navigation",
              icon: "menu",
              actionIfNoSubItems: _vm.toggleMenu,
            },
          }),
          _c("div", { staticClass: "middle" }, [
            _c("div", { staticClass: "logoWrapper" }, [
              _c("img", { attrs: { src: _vm.logo } }),
            ]),
            _vm.schemeName
              ? _c(
                  "div",
                  { staticClass: "schemeName documenta" },
                  [
                    _c("div", [_c("span", [_vm._v(_vm._s(_vm.schemeName))])]),
                    _c("date-picker", {
                      attrs: {
                        inputTemplate: _vm.datePickerInputTemplate,
                        valueOverride: _vm.calculationDate,
                      },
                      on: { currentvalue: _vm.dateChanged },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$store.getters.getAuthState,
                  expression: "$store.getters.getAuthState",
                },
              ],
              staticClass: "user",
              class: { hasNoAuth: _vm.$store.getters.getAuthState == null },
            },
            [
              _vm.showBackToSchemes & _vm.termsUpToDate
                ? _c("dropdown-icon", {
                    staticClass: "hideMobile",
                    attrs: {
                      "tool-tip": "Back to schemes",
                      icon: "home",
                      actionIfNoSubItems: _vm.backToSchemes,
                    },
                  })
                : _vm._e(),
              _vm.lcpUser & _vm.termsUpToDate
                ? _c("dropdown-icon", {
                    staticClass: "hideMobile",
                    attrs: {
                      "tool-tip": "Go to scheme management",
                      icon: "settings",
                      actionIfNoSubItems: _vm.backToManageSchemes,
                    },
                  })
                : _vm._e(),
              _vm.termsUpToDate
                ? _c("dropdown-icon", {
                    staticClass: "hideMobile",
                    attrs: { icon: "info", subItems: _vm.infoDropdown },
                  })
                : _vm._e(),
              _vm.user != null
                ? _c("dropdown-icon", {
                    staticClass: "logout",
                    attrs: {
                      "tool-tip": "Logout",
                      icon: "Logout",
                      actionIfNoSubItems: _vm.signOut,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }