import { render, staticRenderFns } from "./riskAttributionChart.vue?vue&type=template&id=45147ef4&"
import script from "./riskAttributionChart.vue?vue&type=script&lang=js&"
export * from "./riskAttributionChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/Lcp.VisualisePro/Lcp.VisualisePro.WebVue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45147ef4')) {
      api.createRecord('45147ef4', component.options)
    } else {
      api.reload('45147ef4', component.options)
    }
    module.hot.accept("./riskAttributionChart.vue?vue&type=template&id=45147ef4&", function () {
      api.rerender('45147ef4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/charts/riskAttributionChart.vue"
export default component.exports