var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("highchart", {
        key: "riskAttribution",
        attrs: {
          options: _vm.currentChartOptionsRiskAttribution,
          "series-options": _vm.currentChartSeriesRiskAttribution,
        },
      }),
      _vm.$mq != "xs"
        ? _c("checkbox", {
            staticStyle: { width: "180px", margin: "10px auto 0px" },
            attrs: { inputTemplate: _vm.checkboxTemplate },
            on: { toggled: _vm.showCompare },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }