var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _vm._m(0),
          _c("div", { ref: "chart", staticClass: "cashflowchart LCPcontent" }),
          _c("div", { staticClass: "LCPcontent" }, [
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.displayOption, function (option, key) {
                return _c(
                  "div",
                  { key: key, staticClass: "col-md-3" },
                  [
                    _c("lcp-button", {
                      key: key,
                      attrs: {
                        id: option,
                        text: option,
                        handleClick: _vm.toggleOption,
                        active: _vm.activeKey === option,
                        disabled: _vm.inTransition,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _c("vue-markdown", {
            staticClass: "LCPcontent",
            attrs: { source: _vm.config.info },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h1", [_vm._v("Cashflow analysis")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }