var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lcpcopyright", class: [_vm.colorTheme] }, [
    _vm.userSession
      ? _c("span", { staticClass: "text sessionText" }, [
          _vm._v(_vm._s(_vm.userSession)),
        ])
      : _vm._e(),
    _c("span", { staticClass: "text copyrightText" }, [
      _vm._v(
        "All content © Copyright " +
          _vm._s(new Date().getFullYear()) +
          " Lane Clark & Peacock LLP All rights reserved"
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }