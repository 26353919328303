var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sideNavBar",
      class: { mobileOpen: _vm.$store.state.toggleMenu },
    },
    [
      _c("ul", { staticClass: "items mobileItems spacer" }, [
        _c("li", { on: { click: _vm.backToSchemes } }, [_vm._m(0)]),
      ]),
      _c(
        "ul",
        { staticClass: "items spacer" },
        _vm._l(_vm.nav, function (navItem, i) {
          return _c(
            "li",
            {
              key: i,
              on: {
                click: function ($event) {
                  return _vm.navigate(navItem.url)
                },
              },
            },
            [
              _c(
                "div",
                {
                  class:
                    _vm.$store.state.session.pageName === navItem.pageName
                      ? "activeItem"
                      : "",
                },
                [
                  _c("span", { staticClass: "navIcon" }, [
                    _c("i", { class: ["icon", "icon-" + navItem.icon] }),
                  ]),
                  navItem.additionalOptions
                    ? _c("span", { staticClass: "settingIconSmall" }, [
                        _c("i", {
                          class: [
                            "icon",
                            "icon-" + navItem.additionalOptions.subIcon,
                          ],
                        }),
                      ])
                    : _vm._e(),
                  _c("span", { staticClass: "navBarName" }, [
                    _vm._v(_vm._s(navItem.navBarName)),
                  ]),
                  navItem.additionalOptions
                    ? _c(
                        "span",
                        {
                          staticClass: "settingIcon",
                          class:
                            _vm.$store.state.session.pageName ===
                            navItem.pageName
                              ? ""
                              : "hide",
                          on: {
                            click: function ($event) {
                              return _vm.onClick(navItem.additionalOptions)
                            },
                          },
                        },
                        [
                          _c("i", {
                            class: [
                              "icon",
                              "icon-" + navItem.additionalOptions.subIcon,
                            ],
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _c("ul", { staticClass: "items mobileItems" }, [
        _c(
          "li",
          {
            on: {
              click: function ($event) {
                return _vm.routeToSchemePage("information")
              },
            },
          },
          [_vm._m(1)]
        ),
        _c(
          "li",
          {
            on: {
              click: function ($event) {
                return _vm.routeToSchemePage("contacts")
              },
            },
          },
          [_vm._m(2)]
        ),
        _c(
          "li",
          {
            on: {
              click: function ($event) {
                return _vm.routeToSchemePage("terms")
              },
            },
          },
          [_vm._m(3)]
        ),
        _c(
          "li",
          {
            on: {
              click: function ($event) {
                return _vm.routeToSchemePage("privacy")
              },
            },
          },
          [_vm._m(4)]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "navIcon" }, [
        _c("i", { staticClass: "icon icon-home" }),
      ]),
      _c("span", { staticClass: "navBarName" }, [_vm._v("Back to schemes")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "navIcon" }, [
        _c("i", { staticClass: "icon icon-info" }),
      ]),
      _c("span", { staticClass: "navBarName" }, [_vm._v("Key information")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "navIcon" }, [
        _c("i", { staticClass: "icon icon-info" }),
      ]),
      _c("span", { staticClass: "navBarName" }, [_vm._v("Contacts")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "navIcon" }, [
        _c("i", { staticClass: "icon icon-info" }),
      ]),
      _c("span", { staticClass: "navBarName" }, [_vm._v("Terms")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "navIcon" }, [
        _c("i", { staticClass: "icon icon-info" }),
      ]),
      _c("span", { staticClass: "navBarName" }, [_vm._v("Privacy")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }