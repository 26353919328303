var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.currentView === _vm.pricingView
        ? _c(
            "div",
            [
              _c("highchart", {
                key: "pricing",
                staticClass: "LCPcontent",
                attrs: {
                  options: _vm.pricingOptions,
                  "series-options": _vm.pricingSeries,
                },
              }),
              _vm.config.pricingDescription
                ? _c("vue-markdown", {
                    staticClass: "LCPcontent",
                    attrs: { source: _vm.config.pricingDescription },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.currentView === _vm.longevityRiskReductionView
        ? _c("highchart", {
            key: "longevityRiskReduction",
            staticClass: "LCPcontent",
            attrs: {
              options: _vm.longevityRiskReductionOptions,
              "series-options": _vm.longevityRiskReductionSeries,
            },
          })
        : _vm._e(),
      _vm.currentView === _vm.diversifiedRiskReductionView
        ? _c("highchart", {
            key: "diversifiedRiskReduction",
            staticClass: "LCPcontent",
            attrs: {
              options: _vm.diversifiedRiskReductionOptions,
              "series-options": _vm.diversifiedRiskReductionSeries,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "LCPcontent" }, [
        _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.viewOptions, function (option, key) {
            return _c(
              "div",
              { key: key, staticClass: "col-md-4" },
              [
                _c("lcp-button", {
                  key: key,
                  attrs: {
                    id: option,
                    text: option,
                    handleClick: _vm.onViewChange,
                    active: _vm.currentView === option,
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }