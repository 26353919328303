var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.chartOptions ? { minHeight: "90px" } : {} },
    [
      _vm.chartOptions
        ? _c("highchart", {
            staticClass: "investment-component-pie-chart",
            attrs: {
              options: _vm.chartOptions,
              "series-options": _vm.chartSeries,
              showXsChart: true,
            },
          })
        : _vm._e(),
      _c("standardMetrics", {
        class: _vm.chartOptions ? "metric" : "",
        attrs: { id: _vm.id, tileData: _vm.dynamicTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }