var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rangeWrapper" },
    [
      _c(
        "div",
        { staticClass: "slideTextInputWrapper" },
        [
          _c("text-input", {
            staticClass: "rangeInput",
            staticStyle: { "padding-right": "10px" },
            attrs: {
              id: _vm.id + "start",
              value: _vm.value[0],
              displayType: _vm.config.displayType,
              lowerBound:
                _vm.config.minimumArray != null
                  ? _vm.config.minimumArray[0]
                  : _vm.config.options[0],
              upperBound:
                _vm.config.maximumArray != null
                  ? _vm.config.maximumArray[0]
                  : _vm.config.options[_vm.config.options.length - 1],
            },
            on: { valueUpdated: _vm.onTextValueUpdatedStart },
          }),
          _c("div", { staticStyle: { "padding-top": "5px" } }, [_vm._v("to")]),
          _c("text-input", {
            staticClass: "rangeInput",
            staticStyle: { "padding-left": "10px" },
            attrs: {
              id: _vm.id + "end",
              value: _vm.value[1],
              displayType: _vm.config.displayType,
              lowerBound:
                _vm.config.minimumArray != null
                  ? _vm.config.minimumArray[1]
                  : _vm.config.options[0],
              upperBound:
                _vm.config.maximumArray != null
                  ? _vm.config.maximumArray[1]
                  : _vm.config.options[_vm.config.options.length - 1],
            },
            on: { valueUpdated: _vm.onTextValueUpdatedEnd },
          }),
        ],
        1
      ),
      _c("basicSlider", {
        staticClass: "sliderContainer",
        staticStyle: { "margin-top": "5px" },
        attrs: {
          changed: _vm.changed,
          disabled: _vm.lockedState,
          maxIndex: _vm.config.options.length - 1,
          maxIndexAllowed: _vm.maxIndexArray,
          minIndex: 0,
          minIndexAllowed: _vm.minIndexArray,
          onDragEnd: _vm.endSlide,
          onDragStart: _vm.startSlide,
          processDragable: true,
          speed: 0.3,
          tooltipFormatter: _vm.sliderFormatter,
          value: _vm.indexArray,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }