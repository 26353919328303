var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "viewNoScheme" },
    [
      _c(
        "div",
        { staticClass: "card-header-large" },
        [
          _c("h1", [
            _vm._v(
              "Manage schemes (" +
                _vm._s(_vm.selectedSchemeCount) +
                " out of " +
                _vm._s(_vm.schemes.length) +
                " selected)"
            ),
          ]),
          _c("lcp-button", {
            staticClass: "refresh",
            attrs: { text: "Refresh schemes", handleClick: _vm.refreshSchemes },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "LCPcontent" }, [
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "div",
              { staticClass: "tr headerRow" },
              [
                _c(
                  "div",
                  { staticClass: "td" },
                  [
                    _c("lcp-button", {
                      staticClass: "selectAll",
                      attrs: {
                        text: _vm.toggleText,
                        handleClick: _vm.toggleAll,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "td" }, [_vm._v("Login history")]),
                _vm._l(_vm.user.loadedFileSystemTypes, function (info) {
                  return _c(
                    "div",
                    { key: info.fileSystem, staticClass: "td" },
                    [
                      _vm._v(" " + _vm._s(info.fileSystem) + " "),
                      _c("span", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: info.description,
                            expression: "info.description",
                          },
                        ],
                        staticClass: "icon icon-info",
                      }),
                    ]
                  )
                }),
                _c("div", { staticClass: "td" }, [
                  _vm._v("Days since live setup updated"),
                ]),
              ],
              2
            ),
            _vm._l(_vm.schemes, function (scheme) {
              return _c(
                "div",
                { key: scheme.id, staticClass: "tr body schemeRow" },
                [
                  _c(
                    "div",
                    { staticClass: "td optionWrapper" },
                    [
                      _c("checkbox", {
                        attrs: {
                          id: scheme.id,
                          inputTemplate: _vm.getInputTemplate(scheme),
                          "value-override": scheme.visible,
                        },
                        on: { toggled: _vm.toggleSingle },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "td" }, [
                    _c(
                      "button",
                      {
                        staticClass: "login-history-button",
                        on: {
                          click: function ($event) {
                            return _vm.setLoginData(scheme)
                          },
                        },
                      },
                      [_c("span", { staticClass: "icon icon-user" })]
                    ),
                  ]),
                  _vm._l(
                    _vm.getFileSystemDataForScheme(scheme),
                    function (fileSystemData, fileSystem) {
                      return _c(
                        "div",
                        {
                          key: scheme.id + "_" + fileSystem,
                          staticClass: "td",
                        },
                        [
                          _c("lcp-button", {
                            staticClass: "timeStampButton",
                            class:
                              !fileSystemData || !fileSystemData.showButton
                                ? "inactive"
                                : "",
                            style: {
                              background: fileSystemData
                                ? fileSystemData.buttonColour
                                : null,
                              borderColor: fileSystemData
                                ? fileSystemData.buttonColour
                                : null,
                            },
                            attrs: {
                              disabled: !fileSystemData,
                              text: fileSystemData
                                ? fileSystemData.timeStamp.ToDateTimeString()
                                : "n/a",
                              handleClick: _vm.navigateTo(
                                scheme.id,
                                fileSystem
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                  _c("div", { staticClass: "td" }, [
                    _vm._v(_vm._s(_vm.getDaysSinceLiveSetupUpdated(scheme))),
                  ]),
                ],
                2
              )
            }),
          ],
          2
        ),
      ]),
      _vm.selectedSchemeForLoginHistory
        ? _c("login-history-modal", {
            attrs: { schemeInfo: _vm.selectedSchemeForLoginHistory },
            on: { close: _vm.closeLoginModel },
          })
        : _vm._e(),
      _c("copyright", { attrs: { colorTheme: "dark" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }