var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inputMargin" },
    [
      _vm.config.label
        ? _c("div", [_vm._v(" " + _vm._s(_vm.config.label) + " ")])
        : _vm._e(),
      _c("multiselect", {
        class: [
          _vm.valueChanged,
          {
            titleHeader: _vm.titleHeader,
            removeDropDown: _vm.config.options.length < 2,
          },
        ],
        attrs: {
          value: _vm.selected,
          options: _vm.config.options,
          "show-labels": false,
          searchable: false,
          "allow-empty": false,
        },
        on: { select: _vm.dropdownSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }