var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "acceptTermsBar" },
    [
      _c("checkbox", {
        staticClass: "termsCheckbox",
        attrs: { inputTemplate: _vm.checkboxTemplate },
        on: { toggled: _vm.toggleCheck },
      }),
      _c(
        "div",
        { staticClass: "buttonwrapper" },
        [
          _c("lcp-button", {
            staticClass: "button",
            attrs: {
              text: "Accept",
              disabled: !_vm.read,
              handleClick: _vm.saveTerms,
            },
          }),
          !_vm.read
            ? _c("lcp-button", {
                staticClass: "button",
                attrs: { text: "Logout", handleClick: _vm.signOut },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }