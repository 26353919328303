var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "inputMargin" }, [
    _vm._v(" " + _vm._s(_vm.sliderDescription) + " "),
    _c(
      "div",
      { staticClass: "stepperWrapper" },
      [
        _c(
          "div",
          {
            staticClass: "stepper decrease",
            class:
              _vm.lowerBound && _vm.value === _vm.lowerBound ? "disabled" : "",
            on: { click: _vm.decrease },
          },
          [_vm._v("-")]
        ),
        _c("text-input", {
          staticStyle: { display: "inline-block" },
          attrs: {
            id: _vm.id,
            value: _vm.value,
            displayType: _vm.config.displayType,
            relative: _vm.config.relative,
            relativeTo: _vm.config.relativeTo,
            decimalPlacesOverride: _vm.config.decimalPlacesOverride,
            valueChanged: _vm.valueChanged,
            lowerBound: _vm.lowerBound,
            upperBound: _vm.upperBound,
          },
          on: { valueUpdated: _vm.onTextValueUpdated },
        }),
        _c(
          "div",
          {
            staticClass: "stepper increase",
            class:
              _vm.upperBound && _vm.value === _vm.upperBound ? "disabled" : "",
            on: { click: _vm.increase },
          },
          [_vm._v("+")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }