var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageAvailable
    ? _c("div", { staticClass: "row derisking" }, [
        _c(
          "div",
          {
            staticClass: "col-md-8 framed partialPageCardLeft",
            attrs: { id: "mainCard" },
          },
          [
            _c(
              "div",
              { staticClass: "card" },
              [
                _c("div", {
                  class: _vm.inputs[_vm.calcSuspendedId].value
                    ? "disabledBackground"
                    : "",
                }),
                _c("toggle-header", {
                  attrs: {
                    tabs: _vm.resultsTabs,
                    viewOverride: _vm.currentResultsView,
                    title: _vm.dropdownTitle == null ? "Derisking" : null,
                    dropdownTitle: _vm.dropdownTitle,
                    responsiveSize: "sm",
                  },
                  on: { currentView: _vm.onResultsViewChange },
                }),
                _vm.currentResultsView === _vm.summaryView
                  ? _c("lcp-table", {
                      staticClass: "LCPcontent",
                      attrs: {
                        config: _vm.summaryTableWithIcons,
                        id: _vm.summaryView,
                      },
                    })
                  : _vm._e(),
                _c("derisking-Table", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.tabsToRemoveLockTable.includes(
                        _vm.currentResultsView
                      ),
                      expression:
                        "!tabsToRemoveLockTable.includes(currentResultsView)",
                    },
                  ],
                  attrs: { lockTableData: _vm.lockTableData, id: "lockTable" },
                }),
                _vm.config.interfaceOptions.showProgressTab &&
                _vm.currentResultsView === _vm.progressView
                  ? _c("progress-tab", {
                      attrs: {
                        lockTableData: _vm.lockTableData,
                        config: _vm.config.progressConfig,
                        dynamicResult: _vm.dynamicOutput.progressResult,
                      },
                    })
                  : _vm._e(),
                _vm.config.interfaceOptions.showHeadroomTab &&
                _vm.currentResultsView === _vm.headroomView
                  ? _c("headroom-tab", {
                      attrs: {
                        config: _vm.config.headroomConfig,
                        dynamicResult: _vm.dynamicOutput.headroomResult,
                      },
                    })
                  : _vm._e(),
                _vm.config.interfaceOptions.showRiskTab &&
                _vm.currentResultsView === _vm.riskView
                  ? _c("risk-tab", {
                      attrs: {
                        lockTableData: _vm.lockTableData,
                        config: _vm.config.riskConfig,
                        dynamicResult: _vm.dynamicOutput.riskResult,
                      },
                    })
                  : _vm._e(),
                _vm.config.interfaceOptions.showHedgingTab &&
                _vm.currentResultsView === _vm.hedgingView
                  ? _c("hedging-tab", {
                      attrs: {
                        config: _vm.config.hedgingConfig,
                        dynamicResult: _vm.dynamicOutput.hedgingResult,
                      },
                    })
                  : _vm._e(),
                _vm.config.interfaceOptions.showPricingTab &&
                _vm.currentResultsView === _vm.pricingView
                  ? _c("pricing-tab", {
                      attrs: { config: _vm.config.pricingConfig },
                    })
                  : _vm._e(),
                _vm.config.interfaceOptions.showCollateralTab &&
                _vm.currentResultsView === _vm.collateralOvertimeView
                  ? _c("collateral-overtime-tab", {
                      attrs: {
                        dynamicOutput:
                          _vm.dynamicOutput.collateralOvertimeResult,
                      },
                    })
                  : _vm._e(),
                _vm.currentResultsView === _vm.collateralShocksView
                  ? _c("collateral-shocks-tab", {
                      attrs: {
                        config: _vm.config.collateralShocksConfig,
                        dynamicResult: _vm.dynamicOutput.collateralShocksResult,
                      },
                    })
                  : _vm._e(),
                _vm.currentResultsView === _vm.accountingView
                  ? _c("derisking-Table", {
                      attrs: {
                        lockTableData: _vm.lockTableData,
                        tableConfig: _vm.dynamicOutput.accountingResultsTable,
                        id: _vm.accountingView,
                      },
                    })
                  : _vm._e(),
                _vm.currentResultsView === _vm.scenarioComparisonView
                  ? _c("scenario-comparison-tab", {
                      attrs: {
                        config: _vm.config.scenarioComparisonConfig,
                        dynamicResult:
                          _vm.dynamicOutput.scenarioComparisonResult,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "col-md-4 partialPageCardRight" }, [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c("toggle-header", {
                attrs: {
                  tabs: _vm.sliderTabs,
                  dropdownTitle: _vm.scenarioDropdown,
                  "title-value-override": _vm.scenarioId,
                  includeReset: true,
                },
                on: {
                  currentView: _vm.onSliderViewChange,
                  dropdownChange: _vm.scenarioUpdated,
                },
              }),
              _c("input-wrapper", {
                attrs: { inputTemplate: _vm.inputs[_vm.calcSuspendedId] },
              }),
              _vm.dynamicOutput.headlineResults.warningTable
                ? _c("lcp-table", {
                    staticClass: "col-md-12 removeXsPadding",
                    attrs: {
                      config: this.dynamicOutput.headlineResults.warningTable,
                    },
                  })
                : _vm._e(),
              _vm.dynamicOutput.headlineResults.limitTable
                ? _c("lcp-table", {
                    staticClass: "col-md-12 removeXsPadding",
                    attrs: {
                      config: this.dynamicOutput.headlineResults.limitTable,
                    },
                  })
                : _vm._e(),
              _c("lcp-table", {
                staticClass: "col-md-12 removeXsPadding",
                attrs: {
                  config: _vm.matchingPortfolioTableData,
                  formattingOverrides: _vm.tableFormattingOverrides,
                },
              }),
              _vm.currentSliderView === _vm.assetSliderView
                ? _c(
                    "div",
                    { staticClass: "col-md-12 input-container" },
                    _vm._l(
                      _vm.assetWithBuyinSliderInputs,
                      function (input, key) {
                        return _c("input-wrapper", {
                          key: key,
                          attrs: {
                            inputTemplate: input,
                            "value-override": _vm.inputOverrides(key),
                          },
                          on: { currentvalue: _vm.inputsChanged },
                        })
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm.currentSliderView === _vm.hedgingSliderView
                ? _c(
                    "div",
                    { staticClass: "col-md-12 input-container" },
                    _vm._l(_vm.hedgingSliderInputs, function (input, key) {
                      return _c("input-wrapper", {
                        key: key,
                        attrs: {
                          inputTemplate: input,
                          "value-override": _vm.inputOverrides(key),
                        },
                        on: { currentvalue: _vm.inputsChanged },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.currentSliderView === _vm.buyinSliderView
                ? _c(
                    "div",
                    [
                      _c("lcp-table", {
                        staticClass: "col-md-12 removeXsPadding",
                        staticStyle: { "margin-top": "10px" },
                        attrs: {
                          config: _vm.buyinLiabilitySummaryData,
                          formattingOverrides: _vm.tableFormattingOverrides,
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "col-md-12 input-container" },
                        _vm._l(_vm.buyinSliderInputs, function (input, key) {
                          return _c("input-wrapper", {
                            key: key,
                            attrs: {
                              inputTemplate: input,
                              "value-override": _vm.inputOverrides(key),
                            },
                            on: { currentvalue: _vm.inputsChanged },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ])
    : _c(
        "div",
        { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } },
        [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c("div", { staticClass: "card-header" }, [
                _c("h1", [_vm._v(_vm._s(_vm.header))]),
              ]),
              _c("vue-markdown", {
                staticClass: "LCPcontent",
                attrs: { source: _vm.pageNotAvailableText },
              }),
            ],
            1
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }