var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "full-height", attrs: { id: "schemeSelecter" } },
    [
      _c(
        "div",
        { staticClass: "full-height", staticStyle: { position: "fixed" } },
        [
          _c(
            "div",
            {
              staticClass: "col-md-6 col-lg-4 leftColumn full-height",
              staticStyle: { height: "calc(100% - 60px)" },
            },
            [
              _c("div", { staticClass: "buttonGroup" }, [
                _c(
                  "div",
                  { staticClass: "loginGroup" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "login", attrs: { to: "landingLocal" } },
                      [_vm._v("Client login")]
                    ),
                    _c(
                      "router-link",
                      { staticClass: "link", attrs: { to: "register" } },
                      [_vm._v("or Register")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "loginGroup" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "login lcp", attrs: { to: "landingLcp" } },
                      [_vm._v("LCP login")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c("router-link", { attrs: { to: "/terms" } }, [
                    _vm._v("Terms & conditions"),
                  ]),
                  _vm._v(" | "),
                  _c("router-link", { attrs: { to: "/privacy" } }, [
                    _vm._v("Privacy policy"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-md-6 col-lg-8 col-xs-12 rightColumn",
              staticStyle: { position: "relative" },
            },
            [
              _c(
                "div",
                { staticClass: "siemaWrapper" },
                [
                  _c(
                    "siema",
                    {
                      ref: "siema",
                      staticClass: "siema documenta",
                      attrs: {
                        "auto-play": "",
                        options: {
                          threshold: 0,
                          loop: true,
                          duration: 500,
                          easing: "ease-out",
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "slide" }, [
                        _vm._v(
                          " LCP Visualise is a superb web-enabled software tool that enables better pension scheme performance monitoring and facilitates proactive, strategic and efficient de-risking decision making. "
                        ),
                      ]),
                      _c("div", { staticClass: "slide" }, [
                        _vm._v(
                          " LCP Visualise enables me to provide up-to-date funding indications to my management colleagues in minutes whenever I need them. I particularly like the fact that you can move the cursor and get a figure at a specific date. "
                        ),
                      ]),
                      _c("div", { staticClass: "slide" }, [
                        _vm._v(
                          " LCP Visualise adds great value to our meetings. It is clear, quick and easy to follow and as a consequence we get richer, more focussed discussion and I feel it improves our decision making on funding and investment matters. I’m very impressed that LCP has this analytical tool so well developed to provide the level of Adviser support you hope for. "
                        ),
                      ]),
                      _c("div", { staticClass: "slide" }, [
                        _vm._v(
                          " LCP Visualise is a really useful tool to facilitate collaboration between the trustees and company. We can quickly see the impact of different funding and investment decisions in a very intuitive way, and keep on top of how the position is changing over time. It helps us to focus on the decisions that make a real difference. "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }