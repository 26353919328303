var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("derisking-Table", {
        attrs: {
          lockTableData: _vm.lockTableData,
          tableConfig: _vm.dynamicResult.headlineResultsTable,
        },
      }),
      _c("highchart", {
        key: "recoveryProgress",
        staticClass: "LCPcontent",
        attrs: { options: _vm.chartOptions, "series-options": _vm.chartSeries },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }