var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      staticClass: "chart",
      class: [_vm.showXsChart === true ? "" : "xsResponsiveChart"],
      attrs: { id: _vm.id },
    }),
    _c(
      "div",
      {
        staticClass: "hiddenLabel",
        class: [
          _vm.showXsChart === true || _vm.hideHiddenChartText === true
            ? ""
            : "responsive",
        ],
      },
      [
        _vm._v(
          " If viewing on a phone, please rotate your screen in order to view this chart. "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }