var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-10 col-xs-offset-1 LCPcontent" },
      [
        _c("input-wrapper", {
          attrs: {
            inputTemplate: _vm.dateInputs,
            "value-override": _vm.overrideValue,
          },
          on: { updatePageProcess: _vm.updateSliderEndDate },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "col-xs-10 col-xs-offset-1" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-3" },
          [
            _c("lcp-button", {
              attrs: {
                handleClick: _vm.onPeriodSelect(_vm.sinceValuationDatePeriod),
                text: _vm.sinceValuationDateKey,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-3" },
          [
            _c("lcp-button", {
              attrs: {
                handleClick: _vm.onPeriodSelect(_vm.previousYearPeriod),
                text: _vm.previousYearKey,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-3" },
          [
            _c("lcp-button", {
              attrs: {
                handleClick: _vm.onPeriodSelect(_vm.previousQuarterPeriod),
                text: _vm.previousQuarterKey,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-3" },
          [
            _c("lcp-button", {
              attrs: {
                handleClick: _vm.onPeriodSelect(_vm.previousMonthPeriod),
                text: _vm.previousMonthKey,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }