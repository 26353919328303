var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header dropdownTitle" },
    [
      _vm.title !== _vm.noPrefix
        ? _c("span", {
            staticClass: "h1 paddedRight",
            domProps: { innerHTML: _vm._s(_vm.title) },
          })
        : _vm._e(),
      _vm.inputTemplate
        ? _c("dropdown", {
            staticClass: "customDropdown",
            attrs: {
              inputTemplate: _vm.inputTemplate,
              valueOverride: _vm.valueOverride,
              "title-header": "",
            },
            on: { currentvalue: _vm.dropdownSelect },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }