var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card tile",
      style: { backgroundColor: "beige", color: "black" },
    },
    [
      _c("div", { staticClass: "card-header tile-header" }, [
        _c("div", [_vm._v(" ")]),
        _c("span", {
          staticClass: "clickable icon icon-Delete",
          staticStyle: { right: "70px" },
          on: { click: _vm.remove },
        }),
        _c("span", {
          staticClass: "clickable icon icon-plus",
          staticStyle: { right: "35px" },
          on: { click: _vm.add },
        }),
        _c("span", {
          staticClass: "clickable icon icon-edit",
          on: { click: _vm.edit },
        }),
      ]),
      _c(
        "div",
        { staticClass: "tile-content" },
        [
          _c("vue-markdown", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.editMode,
                expression: "!editMode",
              },
            ],
            staticClass: "tileRow",
            attrs: { source: _vm.text },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editMode,
                  expression: "editMode",
                },
              ],
              staticClass: "tileRow",
            },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.text,
                    expression: "text",
                  },
                ],
                staticClass: "textBox",
                attrs: { name: "textarea" },
                domProps: { value: _vm.text },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.text = $event.target.value
                  },
                },
              }),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }