var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "control",
      class: [
        _vm.inputWrapperClass,
        _vm.config.radioMode ? "radioMode" : "checkboxMode",
        _vm.config.largerFontSize ? "largerFontSize" : "",
      ],
      style: { color: _vm.labelColour },
    },
    [
      _vm._v(_vm._s(_vm.label) + " "),
      _c("input", {
        attrs: {
          type: "checkbox",
          disabled: _vm.config.radioMode && _vm.checked,
        },
        domProps: { checked: _vm.checked },
        on: { change: _vm.onCheckChange },
      }),
      _c("div", { staticClass: "checkmark" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }