var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("lcpButton", {
    attrs: {
      handleClick: _vm.downloadReport,
      text: _vm.btnString,
      iconToUse: "icon-download-cloud",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }