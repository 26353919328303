var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("standardMetrics", {
    staticClass: "tileMetricTableStartEndFormatting",
    attrs: { id: _vm.id, tileData: _vm.tileData },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }