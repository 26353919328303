var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _vm._m(0),
          _vm.description
            ? _c("vue-markdown", {
                staticClass: "LCPcontent",
                attrs: { source: _vm.description },
              })
            : _vm._e(),
          _c("highchart", {
            staticClass: "LCPcontent",
            attrs: {
              options: _vm.chartOptions,
              "series-options": _vm.chartSeries,
              showXsChart: true,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h1", [_vm._v("Integrated risk overview")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }