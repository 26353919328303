var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "covenant-component-dial" },
      [
        _c("highchart", {
          staticClass: "covenant-component-dial-chart",
          attrs: {
            options: _vm.chartOptions,
            "series-options": _vm.chartSeries,
            showXsChart: true,
          },
        }),
        _c("div", { staticClass: "covenant-component-dial-text" }, [
          _vm._v(_vm._s(_vm.strength)),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "tileRow" }, [
      _vm._v(
        "The " +
          _vm._s(_vm.assessedByDisplayText) +
          " assessed the employer covenant as "
      ),
      _c("b", [_vm._v(_vm._s(_vm.strength))]),
      _vm._v(" as at "),
      _c("b", [_vm._v(_vm._s(_vm.dateOfAssessment))]),
      _vm._v("."),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }