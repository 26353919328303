var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageAvailable
    ? _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-md-8 framed partialPageCardLeft" }, [
            _c(
              "div",
              { staticClass: "card" },
              [
                _c("toggle-header", {
                  attrs: {
                    title: "Fast-Track dashboard",
                    tabs: _vm.tabs,
                    viewOverride: _vm.currentView,
                  },
                  on: { currentView: _vm.onCurrentViewUpdate },
                }),
                _vm.currentView === _vm.trafficLights
                  ? [
                      _c(
                        "div",
                        { staticClass: "LCPcontent" },
                        [
                          _c("p", [
                            _vm._v(
                              "Comparison with LCP’s Fast Track Forecast benchmarks. Please note Fast Track is expected to be relevant for all schemes including those using a Bespoke approach. LCP Fast Track Forecast is our current best estimate of a minimum Fast Track compliant position."
                            ),
                          ]),
                          _c("lcp-button", {
                            attrs: {
                              handleClick: _vm.openCaveats(),
                              text: "Important notes on our Fast Track dashboard assumptions and methodology",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "LCPcontent" },
                        _vm._l(
                          _vm.tprResults,
                          function (resultsByCategory, category, rowCount) {
                            return _c(
                              "table",
                              {
                                key: category,
                                staticClass: "table",
                                staticStyle: { width: "100%" },
                              },
                              [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "tableRow",
                                      staticStyle: { width: "auto" },
                                      attrs: { align: "left" },
                                    },
                                    [_vm._v(_vm._s(category))]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "tableRow",
                                      staticStyle: { width: "70px" },
                                      attrs: { align: "right" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(rowCount == 0 ? "Rating" : "")
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._l(
                                  resultsByCategory,
                                  function (result, key) {
                                    return _c("tr", { key: key }, [
                                      _c(
                                        "td",
                                        { attrs: { colspan: "2" } },
                                        [
                                          result.caveat != null
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "LCPcontent caveat",
                                                },
                                                [_vm._v(_vm._s(result.caveat))]
                                              )
                                            : _vm._e(),
                                          _vm.getTableData(result) != null
                                            ? _c("lcp-table", {
                                                staticClass: "noShadow",
                                                attrs: {
                                                  config:
                                                    _vm.getTableData(result),
                                                  allowCondense: true,
                                                  initialCondensed: true,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                ),
                              ],
                              2
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  : _vm._e(),
                _vm.currentView === _vm.projectionView
                  ? [
                      _c("div", { staticClass: "LCPcontent inputs-flex" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("input-wrapper", {
                              attrs: {
                                inputTemplate:
                                  _vm.inputs[_vm.config.assetDropdownId],
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("input-wrapper", {
                              attrs: {
                                inputTemplate:
                                  _vm.inputs[_vm.config.fundingDropdownId],
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("highchart", {
                        key: "projectedAandL",
                        staticClass: "LCPcontent",
                        attrs: {
                          options: _vm.projectionChartOptions,
                          "series-options": _vm.chartSeriesAssetAndLiability,
                        },
                      }),
                      _vm.dynamicOutput.chartLabel != null
                        ? _c("div", { staticClass: "LCPcontent" }, [
                            _vm._v(_vm._s(_vm.dynamicOutput.chartLabel)),
                          ])
                        : _vm._e(),
                      _c("lcp-table", {
                        staticClass: "LCPcontent",
                        attrs: { config: _vm.projectionTableData },
                      }),
                      _c("div", { staticClass: "LCPcontent" }, [
                        _vm._v(_vm._s(_vm.dynamicOutput.tableFootnote)),
                      ]),
                    ]
                  : _vm._e(),
                _vm.currentView === _vm.recoveryView
                  ? [
                      _c(
                        "div",
                        { staticStyle: { position: "relative" } },
                        [
                          _vm.blocked
                            ? _c("div", { staticClass: "blockedContent" }, [
                                _c("div", { staticClass: "text" }, [
                                  _c("span", { staticClass: "icon icon-Lock" }),
                                  _vm._v(
                                    " " + _vm._s(_vm.blockedContentText) + " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("highchart", {
                            key: "recoveryPlan",
                            staticClass: "LCPcontent",
                            attrs: {
                              options: _vm.recoveryChartOptions,
                              "series-options": _vm.chartSeriesRecoveryPlan,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "col-md-4 framed partialPageCardRight" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _c("h1", [_vm._v(_vm._s(_vm.headerRight))]),
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", {
                  staticClass: "LCPcontent",
                  domProps: { innerHTML: _vm._s(_vm.subHeaderRight) },
                }),
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("input-wrapper", {
                      attrs: {
                        inputTemplate:
                          _vm.inputs[_vm.config.covenantDropdownId],
                      },
                    }),
                  ],
                  1
                ),
                _vm.inputs[_vm.config.highRiskDiscountRateId]
                  ? _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("input-wrapper", {
                          attrs: {
                            inputTemplate:
                              _vm.inputs[_vm.config.highRiskDiscountRateId],
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.inputs[_vm.config.lowRiskDiscountRateId]
                  ? _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("input-wrapper", {
                          attrs: {
                            inputTemplate:
                              _vm.inputs[_vm.config.lowRiskDiscountRateId],
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                this.inputs[this.config.ldrEndDateId]
                  ? _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("input-wrapper", {
                          attrs: {
                            inputTemplate:
                              this.inputs[this.config.ldrEndDateId],
                            "dynamic-label": _vm.dynamicOutput.ldrEndDateLabel,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showOptions
                  ? _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("input-wrapper", {
                          attrs: {
                            inputTemplate:
                              _vm.inputs[_vm.config.recoveryPlanOptionsId],
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showCalculationOptions
                  ? _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("input-wrapper", {
                          attrs: {
                            inputTemplate:
                              _vm.inputs[_vm.config.recoveryPlanTargetId],
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showCalculationOptions &&
                _vm.showEditRp &&
                _vm.inputs[_vm.config.recoveryPlanReturnsId]
                  ? _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("input-wrapper", {
                          attrs: {
                            inputTemplate:
                              _vm.inputs[_vm.config.recoveryPlanReturnsId],
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showCalculationOptions && _vm.showEditRp
                  ? _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("input-wrapper", {
                          attrs: {
                            inputTemplate:
                              _vm.inputs[_vm.config.recoveryPlanSliderId],
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._m(1),
                _c("div", { staticClass: "col-md-12" }, [
                  _vm.fileCollection.length > 0
                    ? _c(
                        "div",
                        { staticClass: "assumptionButton" },
                        [
                          _c("lcp-button", {
                            attrs: {
                              handleClick: _vm.openFileDownloader,
                              text: "View supporting documents",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "input-container" }, [
                  _vm.includeProposeButton
                    ? _c(
                        "div",
                        { class: _vm.buttonClass },
                        [
                          _c("lcp-button", {
                            staticClass: "iconLarge",
                            attrs: {
                              handleClick: _vm.propose(),
                              text: "Propose this plan",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.includeAdvisorButton
                    ? _c(
                        "div",
                        { class: _vm.buttonClass },
                        [
                          _c("lcp-button", {
                            staticClass: "iconLarge",
                            attrs: {
                              handleClick: _vm.navigateTo(),
                              text: "Speak to an advisor",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
          _vm.showDownloadManager
            ? _c("download-files-modal", {
                attrs: { fileCollection: _vm.fileCollection },
                on: {
                  close: function ($event) {
                    _vm.showDownloadManager = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } },
          [
            _c(
              "div",
              { staticClass: "card" },
              [
                _vm._m(2),
                _c("vue-markdown", {
                  staticClass: "LCPcontent",
                  attrs: { source: _vm.pageNotAvailableText },
                }),
              ],
              1
            ),
          ]
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12" }, [
      _c("hr", { staticStyle: { margin: "10px auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12" }, [
      _c("hr", { staticStyle: { margin: "10px auto" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h1", [_vm._v("Fast-Track dashboard")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }