var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.columnLimiter }, [
    _c("div", { key: _vm.id, staticClass: "table" }, [
      !_vm.responsive
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.config.excludeHeader,
                      expression: "!config.excludeHeader",
                    },
                  ],
                  staticClass: "tr headerRow",
                },
                _vm._l(
                  _vm.config.headerRow,
                  function (headerCell, headerIndex) {
                    return _c(
                      "div",
                      {
                        key: "header" + headerIndex + headerCell.text,
                        staticClass: "td fadeIn animated",
                        class: [
                          _vm.toggleHeaderClass,
                          { open: !_vm.condensed },
                        ],
                        style: _vm.getCellStyle(headerCell, true),
                        on: { click: _vm.toggleCondensed },
                      },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(headerCell.text) },
                        }),
                        headerCell.tableCellStyle &&
                        headerCell.tableCellStyle.iconClass
                          ? _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content:
                                      headerCell.tableCellStyle.tooltipText,
                                    placement:
                                      headerCell.tableCellStyle
                                        .tooltipPlacement,
                                    classes:
                                      headerCell.tableCellStyle.tooltipClass,
                                  },
                                  expression:
                                    "{content: headerCell.tableCellStyle.tooltipText, placement: headerCell.tableCellStyle.tooltipPlacement, classes: headerCell.tableCellStyle.tooltipClass}",
                                },
                              ],
                              class: [
                                headerCell.iconFormatOverride
                                  ? ""
                                  : "iconFormat",
                                headerCell.tableCellStyle.iconClass,
                              ],
                              style: [
                                headerCell.iconStyle,
                                {
                                  cursor: headerCell.tableCellStyle.clicked
                                    ? "pointer"
                                    : "default",
                                },
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.onClick(
                                    headerCell.tableCellStyle.clicked
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  }
                ),
                0
              ),
              _vm.config.subHeaderRow
                ? _c(
                    "div",
                    { staticClass: "tr headerRow" },
                    _vm._l(
                      _vm.config.subHeaderRow,
                      function (headerCell, headerIndex) {
                        return _c(
                          "div",
                          {
                            key: "subheaderRow" + headerIndex + headerCell.text,
                            staticClass: "td fadeIn animated",
                            class: _vm.toggleHeaderClass,
                            style: _vm.getCellStyle(headerCell, true),
                            on: { click: _vm.toggleCondensed },
                          },
                          [_vm._v(_vm._s(headerCell.text))]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm._l(
                _vm.config.cellContentByRowId,
                function (cellsForRow, rowKey, rowIndex) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.getShowRow(
                            _vm.condensed,
                            cellsForRow[0].isTotal
                          ),
                          expression:
                            "getShowRow(condensed, cellsForRow[0].isTotal)",
                        },
                      ],
                      key: "row" + rowIndex,
                      staticClass: "tr body",
                    },
                    _vm._l(cellsForRow, function (rowCell, tdIndex) {
                      return _c(
                        "div",
                        {
                          key: "row" + rowIndex + " cell" + tdIndex,
                          staticClass: "td",
                          style: [
                            _vm.getCellStyle(rowCell),
                            { cursor: rowCell.clicked ? "pointer" : "default" },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.onClick(rowCell.clicked)
                            },
                          },
                        },
                        [
                          rowCell.inputTemplate
                            ? _c(
                                "div",
                                {
                                  key: rowCell.inputTemplate.id + "_input",
                                  staticClass: "inputWrapper",
                                },
                                [
                                  _c("input-wrapper", {
                                    attrs: {
                                      inputTemplate: rowCell.inputTemplate,
                                    },
                                    on: {
                                      currentvalueAndId:
                                        _vm.emitCurrentValueAndId,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  key: rowCell.text,
                                  staticClass: "fadeIn animated",
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(rowCell.text),
                                    },
                                  }),
                                  rowCell.tableCellStyle &&
                                  rowCell.tableCellStyle.iconClass
                                    ? _c("span", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content:
                                                rowCell.tableCellStyle
                                                  .tooltipText,
                                              placement:
                                                rowCell.tableCellStyle
                                                  .tooltipPlacement,
                                              classes:
                                                rowCell.tableCellStyle
                                                  .tooltipClass,
                                            },
                                            expression:
                                              "{content: rowCell.tableCellStyle.tooltipText, placement: rowCell.tableCellStyle.tooltipPlacement, classes: rowCell.tableCellStyle.tooltipClass}",
                                          },
                                        ],
                                        class: [
                                          rowCell.iconFormatOverride
                                            ? ""
                                            : "iconFormat",
                                          rowCell.tableCellStyle.iconClass,
                                        ],
                                        style: [
                                          rowCell.iconStyle,
                                          {
                                            cursor: rowCell.tableCellStyle
                                              .clicked
                                              ? "pointer"
                                              : "default",
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.onClick(
                                              rowCell.tableCellStyle.clicked
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                        ]
                      )
                    }),
                    0
                  )
                }
              ),
            ],
            2
          )
        : _c(
            "div",
            _vm._l(
              _vm.config.cellContentByRowId,
              function (cellsForRow, rowKey, rowIndex) {
                return _c(
                  "div",
                  { key: `row${rowIndex}`, staticClass: "flat" },
                  _vm._l(cellsForRow, function (rowCell, tdIndex) {
                    return _c(
                      "div",
                      {
                        key: `row${rowIndex} cell${tdIndex}`,
                        staticClass: "tr",
                        class: [
                          tdIndex > 0 ? "body" : "headerRow",
                          _vm.flatHeaderOnly(cellsForRow) === -1
                            ? "headerOnly"
                            : "dataOnly",
                        ],
                        style:
                          _vm.flatHeaderOnly(cellsForRow) === -1
                            ? _vm.getCellStyle(
                                rowCell,
                                tdIndex === 0 ? true : false,
                                true
                              )
                            : "",
                      },
                      [
                        !_vm.config.excludeHeader && _vm.config.subHeaderRow
                          ? _c(
                              "div",
                              {
                                staticClass: "td fadeIn animated",
                                class: { tdThird: _vm.config.subHeaderRow },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      tdIndex !== 0 && (tdIndex - 1) % 2 === 0
                                        ? _vm.flatHeader(rowKey)
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        !_vm.config.subHeaderRow
                          ? _c("div", { staticClass: "td fadeIn animated" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.config.headerRow[tdIndex].text) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.config.subHeaderRow
                          ? _c(
                              "div",
                              {
                                staticClass: "td fadeIn animated",
                                class: { tdThird: _vm.config.subHeaderRow },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.config.subHeaderRow[tdIndex].text
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "td fadeIn animated",
                            class: { tdThird: _vm.config.subHeaderRow },
                            style: [
                              _vm.getCellStyle(
                                rowCell,
                                tdIndex === 0 ? true : false,
                                true
                              ),
                              {
                                cursor: rowCell.clicked ? "pointer" : "default",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.onClick(rowCell.clicked)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                key: rowCell.text,
                                staticClass: "fadeIn animated",
                              },
                              [_vm._v(" " + _vm._s(rowCell.text) + " ")]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              }
            ),
            0
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }