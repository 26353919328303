var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageAvailable
    ? _c(
        "div",
        [
          !_vm.childScheme
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-md-12 framed",
                    attrs: { id: "mainCard" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card" },
                      [
                        _c("toggle-header", {
                          attrs: {
                            tabs: _vm.tabs,
                            viewOverride: _vm.currentView,
                            dropdownTitle: _vm.getDropdownTitle(),
                          },
                          on: { currentView: _vm.onCurrentViewChange },
                        }),
                        _vm.currentView === _vm.summaryView
                          ? _c("lcp-table", {
                              staticClass: "LCPcontent",
                              attrs: {
                                config:
                                  _vm.dynamicOutput.currentResult
                                    .headerResultsTable,
                                id: _vm.summaryView,
                              },
                            })
                          : _vm._e(),
                        _vm.dynamicOutput.currentResult
                          .accountingResultsTable !== null &&
                        _vm.currentView == _vm.accountingView
                          ? _c("lcp-table", {
                              staticClass: "LCPcontent",
                              attrs: {
                                config:
                                  _vm.dynamicOutput.currentResult
                                    .accountingResultsTable,
                                id: _vm.accountingView,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm.childScheme
            ? _c("deriskingPage", {
                attrs: {
                  configProp: _vm.childSchemeConfig,
                  inputControlsProp: _vm.inputs,
                  dynamicOutputProp: _vm.dynamicOutput.currentResult,
                  dropdownTitle: _vm.getDropdownTitle(),
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "col-md-12 framed", attrs: { id: "mainCard" } },
        [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c("div", { staticClass: "card-header" }, [
                _c("h1", [_vm._v(_vm._s(_vm.header))]),
              ]),
              _c("vue-markdown", {
                staticClass: "LCPcontent",
                attrs: { source: _vm.pageNotAvailableText },
              }),
            ],
            1
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }