var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "TextInputWidthIdStyle",
        style: [{ width: _vm.textMode ? "220px" : "auto" }],
        attrs: { id: "TextInputWidthId" + _vm.id },
      },
      [
        _vm._v(
          _vm._s(
            _vm.upperBound != null
              ? _vm.getInputValueStrForValue(_vm.upperBound, _vm.relative)
              : _vm.inputValue
          )
        ),
      ]
    ),
    _vm._v(
      " " +
        _vm._s(
          _vm.showRelativeTo
            ? _vm.prefix +
                _vm.getInputValueStrForValue(_vm.relativeTo) +
                _vm.suffixNoPa
            : ""
        ) +
        " " +
        _vm._s(_vm.prefix) +
        " "
    ),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.inputValue,
          expression: "inputValue",
        },
      ],
      class: [_vm.textMode ? "textEdit" : "sliderEdit"],
      style: [
        _vm.lockedState
          ? { "background-color": "transparent", color: "grey" }
          : "",
        { width: _vm.inputWidth },
        _vm.valueChanged ? { "font-weight": "bold" } : "",
      ],
      attrs: { type: "text", disabled: _vm.locked },
      domProps: { value: _vm.inputValue },
      on: {
        blur: _vm.validateInput,
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.validateInput.apply(null, arguments)
        },
        focus: _vm.selectAll,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.inputValue = $event.target.value
        },
      },
    }),
    _vm._v(" " + _vm._s(_vm.suffixToUse) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }