var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "col-md-8 framed partialPageCardLeft",
        attrs: { id: "mainCard" },
      },
      [
        _c(
          "div",
          { staticClass: "card" },
          [
            _c(
              "div",
              { staticClass: "card-header" },
              [
                _c("dropdown-title", {
                  attrs: { inputTemplate: _vm.fundingMeasureDropdownInput },
                  on: { currentvalue: _vm.routeToFundingMeasure },
                }),
              ],
              1
            ),
            _c("lcp-table", {
              staticClass: "LCPcontent",
              attrs: { config: _vm.assetLiabilityTableData },
            }),
            _c("highchart", {
              key: "sensitivityChart",
              staticClass: "LCPcontent",
              attrs: {
                options: _vm.chartOptions,
                "series-options": _vm.chartSeries,
              },
            }),
            _c("vue-markdown", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.description,
                  expression: "description",
                },
              ],
              staticClass: "LCPcontent",
              attrs: { source: _vm.description },
            }),
            _vm.scenarioId === _vm.config.climateScenarioId
              ? _c("div", { staticClass: "LCPcontent" }, [
                  _vm._v(
                    " Climate risk may occur over longer time horizons than your usual risk metrics consider, but could affect the scheme very rapidly at any point and could dwarf other risks. This scenario shows the potential impact of climate risk on the funding position over the longer term (eg through a rapid “pricing in” as asset prices respond to sudden changes in perceptions of climate transition and physical risks, and where economic norms no longer apply). It is hard to estimate when the risks might crystallise, but recent events suggest that climate impacts are unfolding more quickly than previously expected. These risks cannot be diversified away, so to avoid them we would need to limit climate change. Please see "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.lcp.com/media/wmapxk2p/ri-policy-asks-for-clients.pdf",
                        target: "_blank",
                      },
                    },
                    [_vm._v("here")]
                  ),
                  _vm._v(
                    " to see how LCP is using its influence to do this, and how you can add your support to this. "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "col-md-4 partialPageCardRight" }, [
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-header-with-action" },
          [
            _c("dropdown-title", {
              attrs: {
                dropdownPrefix: "Assumptions - ",
                inputTemplate: _vm.scenarioDropdownInput,
                "value-override": _vm.scenarioId,
              },
              on: { currentvalue: _vm.scenarioUpdated },
            }),
            _c("reset-inputs"),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-12 input-container" },
          _vm._l(_vm.inputs, function (input, key) {
            return _c("input-wrapper", {
              key: key,
              attrs: {
                inputTemplate: input,
                "value-override": _vm.inputOverrides(key),
              },
              on: { currentvalue: _vm.inputsChanged },
            })
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }